import React from 'react'

// Customizable Area Start
import {
    Box, Typography, Button, IconButton, Dialog
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import { backArrow, closeIcon } from './assets'
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { webStyles } from './CustomSignatureForm.web';
import { getStorageData } from '../../../framework/src/Utilities';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

import CustomFormTermController, {
    Props,
    configJSON
} from "./CustomFormTermController";
import { ReportProblemOutlined } from '@material-ui/icons';

export class CustomTerms extends CustomFormTermController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const type=await getStorageData('typeOfUser');
        this.getUserProfile();
        type==="seller"?  this.getListings(): this.getDealsRequest();
        this.getContent(this.props.id)
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, id } = this.props;
        const { pageText } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <HeaderBlock />

                <Box className={classes.backGroundContain}>
                    <Box className={classes.container}>

                        <Box className={classes.backArrowCont}>
                            <IconButton data-test-id="backBtn" onClick={this.handleGoBack} style={{ padding: "0" }}>
                                <img src={backArrow} style={this.state.language === "ar" ? {transform: "rotate(180deg)"} : {transform: "rotate(360deg)"}} />
                            </IconButton>
                            <Typography className={classes.backArrowTxt}>{pageText.back}</Typography>
                        </Box>

                        <Box className={classes.headerCont}>
                            <Typography className={classes.headerTxt}>
                            {this.getIfCondition(configJSON[`${id}Header`],configJSON[`${id}HeaderAr`])}
                            </Typography>
                        </Box>
                {this.props.id === "SalePurchaseAgreement" &&
                  <DisclaimerButtonBox>
                    <Button className={`${classes.saveBtn} discButton`}
                      disabled={this.state.isAcknowledged}
                      data-test-id="disclaimerBtnTestId"
                      onClick={this.handleDisclaimer}>
                        <span className='discButtonSpan'>
                      {this.getIfElseCondition(this.state.language === "en",
                        configJSON.acknowledgeDiscText,
                        configJSON.arAcknowledgeDiscText)}
                        </span>
                    </Button>
                  </DisclaimerButtonBox>

                }
                        <Box className={`${classes.mainBoxCont} ${classes.scrollBar}`}>
                            <Typography className={classes.paraHeader}>
                              {this.getIfCondition(configJSON[`${id}TermsHeader`],configJSON[`${id}TermsHeaderAr`])}
                            </Typography>
                            <Box className={classes.paraContainer}>
                    {this.handleConditions(this.state.openDisclaimer,
                      <DisclaimerBox>
                        <Typography className={classes.paraHeader} 
                      data-test-id="disclaimerTextTestId"
                      >
                          {this.getIfElseCondition(this.state.language === "en",
                            configJSON.disclaimerHead,
                            configJSON.arDisclaimerHead)}
                        </Typography>
                        <Box className="disclaimerText">
                          <Typography>
                            {this.getIfElseCondition(this.state.language === "en",
                              configJSON.disclaimerText,
                              configJSON.arDisclaimerText)}
                          </Typography>
                        </Box>
                      </DisclaimerBox>,
                             this.props.id ==="SalePurchaseAgreement" && <div>
                            {this.getIfCondition(<div>
                                <Typography >
                                <p>
                                <span style={{fontWeight:700}}>THIS SALE PURCHASE AGREEMENT</span>
                                (the "Agreement") is made as of
                                <input
                                    type="date"
                                    name="userDate"
                                    value={this.state.userDate}
                                    disabled={!this.state.isAcknowledged}
                                    onChange={this.handleInputChangeUserDate}
                                    data-test="date"
                                    style={{marginLeft:"0px", marginRight:"5px"}}
                                />,
                                by and between
                                <input
                                    type="text"
                                    name="sellerName"
                                    placeholder="Seller's Full Legal Name"
                                    value={this.state.sellerName}
                                    disabled={!this.state.isAcknowledged}
                                    onChange={this.handleInputChangeSellerName}
                                    data-test="sellerName"
                                    className={classes.customInputField}
                                    
                                />,
                                with a  principal place of business at
                                <input
                                    type="text"
                                    name="sellerAddress"
                                    placeholder="Seller's Address"
                                    value={this.state.sellerAddress}
                                    disabled={!this.state.isAcknowledged}
                                    onChange={this.handleInputChangeSellerAddress}
                                    className={classes.customInputField}
                                    data-test="sellerAddress"
                                />
                                (the "Seller"), and
                                <input
                                    type="text"
                                    name="buyerName"
                                    placeholder="Buyer's Full Legal Name"
                                    value={this.state.buyerName}
                                    disabled={!this.state.isAcknowledged}
                                    onChange={this.handleInputChangeBuyerName}
                                    className={classes.customInputField}
                                    style={{marginLeft:"0px", marginRight:"5px"}}
                                    data-test="buyerName"
                                />,
                                with a principal place of business at
                                <input
                                    type="text"
                                    name="buyerAddress"
                                    placeholder="Buyer's Address"
                                    disabled={!this.state.isAcknowledged}
                                    value={this.state.buyerAddress}
                                    onChange={this.handleInputChangeBuyerAddress}
                                    className={classes.customInputField}
                                    data-test="buyerAddress"
                                    style={{marginLeft:"0px", marginRight:"5px"}}
                                />
                                (the "Buyer").
                                </p>
                                </Typography >
                            </div>,
                                <div>
                                 <Typography >
                                <p>
                                <span style={{fontWeight:700}}>أُبرمت اتفاقية البيع والشراء الماثلة</span>
                                  الاتفاقية وحُررت بتاريخ
                                  <input
                                    type="date"
                                    name="userDate"
                                    disabled={!this.state.isAcknowledged}
                                    value={this.state.userDate}
                                    onChange={this.handleInputChangeUserDate}
                                    style={{marginLeft:"5px", marginRight:0}}
                                  />
                                  ، بواسطة وبين كل من
                                  <input
                                    type="text"
                                    name="sellerName"
                                    placeholder="اسم البائع القانوني بالكامل"
                                    disabled={!this.state.isAcknowledged}
                                    value={this.state.sellerName}
                                    onChange={this.handleInputChangeSellerName}
                                    className={classes.customInputField}
                                  />
                                  ، ويقع مقره الرئيسي في
                                  <input
                                    type="text"
                                    name="sellerAddress"
                                    placeholder="عنوان البائع"
                                    disabled={!this.state.isAcknowledged}
                                    value={this.state.sellerAddress}
                                    onChange={this.handleInputChangeSellerAddress}
                                    className={classes.customInputField}
                                  />
                                  (البائع)، و
                                  <input
                                    type="text"
                                    name="buyerName"
                                    placeholder="اسم المشتري القانوني بالكامل"
                                    disabled={!this.state.isAcknowledged}
                                    value={this.state.buyerName}
                                    className={classes.customInputField}
                                    onChange={this.handleInputChangeBuyerName}
                                  />
                                  ، ويقع مقر عمله الرئيسي في
                                  <input
                                    type="text"
                                    name="buyerAddress"
                                    placeholder="عنوان المشتري"
                                    disabled={!this.state.isAcknowledged}
                                    className={classes.customInputField}
                                    value={this.state.buyerAddress}
                                    onChange={this.handleInputChangeBuyerAddress}
                                  />
                                  (المشتري).
                                </p>
                                </Typography >
                                </div>
                            )}

                            { this.getIfCondition(<div>
                            <Typography >
                            <p>
                                  <span style={{ fontWeight: 700 }}>RECITALS: </span>
                                  WHEREAS, the Seller owns
                                  <div className={classes.inputContainer}>
                                    <span className={classes.staticText}>SAR</span>
                                    <input
                                      type="text"
                                      name="amountSpecificPercentage"
                                      disabled={!this.state.isAcknowledged}
                                      placeholder="e.g., the entire amount/specific percentage"
                                      value={this.state.amountSpecificPercentage}
                                      onChange={this.handleInputChangeAmountSpecificPercentage}
                                      data-test="amountSpecificPercentage"
                                      className={classes.inputField}
                                    />
                                  </div>
                                of the shares/assets of
                                <input
                                type="text"
                                name="companyName"
                                disabled={!this.state.isAcknowledged}
                                placeholder="Company Name"
                                value={this.state.companyName}
                                onChange={this.handleInputChnageCompanyName}
                                data-test="companyName"
                                className={classes.customInputField}
                                />,
                                  a
                                <input
                                type="text"
                                name="jurisdiction"
                                placeholder="jurisdiction"
                                value={this.state.jurisdiction}
                                disabled={!this.state.isAcknowledged}
                                onChange={this.handleInputChangeJuridiction}
                                data-test="jurisdiction"
                                className={classes.customInputField}
                                />
                                corporation (the "Company").
                            </p>
                            </Typography >
                            <Typography >
                            <p>
                                WHEREAS, the Seller desires to sell and the Buyer desires to purchase
                                <div className={classes.inputContainer}>
                                    <span className={classes.staticText}>SAR</span>
                                  
                                <input
                                type="text"
                                name="sellerAmount"
                                placeholder="e.g., the entire amount/specific percentage"
                                value={this.state.sellerAmount}
                                onChange={this.handleInputChnageSellerAmount}
                                data-test="sellerAmount"
                                disabled={!this.state.isAcknowledged}
                                className={classes.inputField}
                                style={{marginRight:"5px",marginLeft:"0"}}
                                />
                                </div>
                                of the shares of the Company/the assets of the Company, under the terms and conditions set forth in this Agreement.
                            </p>
                            </Typography >
                            </div>,
                                 <div>
                                    <p>
                                        <span style={{fontWeight:700}}>الديباجة</span>
                                        حيث أن البائع يمتلك
                                        <div className={classes.inputContainer}>

                                        <span className={classes.staticTextAr}>SAR</span>                                  
                                        <input
                                        type="text"
                                        name="amountSpecificPercentage"
                                        placeholder="المبلغ بالكامل/ النسبة المئوية المحددة"
                                        value={this.state.amountSpecificPercentage}
                                        disabled={!this.state.isAcknowledged}
                                        onChange={this.handleInputChangeAmountSpecificPercentage}
                                        className={classes.inputFieldAr}
                                        />

                                        </div>
                                        من أسهم/ أصول
                                        <input
                                        type="text"
                                        name="companyName"
                                        disabled={!this.state.isAcknowledged}
                                        placeholder="اسم الشركة"
                                        value={this.state.companyName}
                                        onChange={this.handleInputChnageCompanyName}
                                        className={classes.customInputField}
                                        />
                                        وهي مؤسسة
                                        <input
                                        type="text"
                                        name="jurisdiction"
                                        placeholder="الولاية القضائية"
                                        disabled={!this.state.isAcknowledged}
                                        value={this.state.jurisdiction}
                                        onChange={this.handleInputChangeJuridiction}
                                        className={classes.customInputField}
                                        />
                                        (الشركة، وحيث أن البائع لديه الرغبة في أن يبع والمشتري لديه الرغبة في الشراء
                                          <div className={classes.inputContainer}>
                                    <span className={classes.staticTextAr}>SAR</span>
                                        <input
                                        type="text"
                                        name="sellerAmount"
                                        placeholder="المبلغ الكامل/ النسبة المئوية المحددة"
                                        value={this.state.sellerAmount}
                                        className={classes.inputFieldAr}
                                        disabled={!this.state.isAcknowledged}
                                        onChange={this.handleInputChnageSellerAmount}
                                        />
                                        </div>
                                        من أسهم الشركة/ أصول الشركة، بموجب الأحكام والشروط الواردة طيه.
                                    </p>
                                </div>)}
                      </div>
                    )}
                    {!this.state.openDisclaimer &&
                                <Typography >
                                    <div dangerouslySetInnerHTML={{__html: this.state.description}}
                                    />
                                </Typography>
                    }    
                            </Box>
                        </Box>
                {this.handleConditions(this.state.openDisclaimer,
                  <DisclaimerButtonBox>
                    <Button className={`${classes.saveBtn} discButton`} data-test-id="acknowledgeBtnTestId" onClick={this.acknowledgeDisclaimer}>
                      {this.getIfElseCondition(this.state.language === "en",
                        configJSON.acknowledgeText,
                        configJSON.arAcknowledgeText)}</Button>
                  </DisclaimerButtonBox>,
                        <Box className={classes.actionBtn}>
                          <Button data-test-id="agreeBtn" onClick={this.agreeTerms} className={classes.saveBtn} disabled={!this.state.isAcknowledged}>
                            {pageText.agree}</Button>
                          <Button className={classes.clearBtn} data-test-id="disAgreeBtn" onClick={this.disagreeTerms}>{pageText.disAgree}</Button>
                        </Box>
                )}
                    </Box>
                </Box>
               
            <Dialog
              aria-labelledby="simple-dialog-title"
              open={this.state.terminateAccess}
              className={classes.terminateAccess}
              scroll="body"
            >
              <div className={classes.learnMoreDialogContent}>
                <ReportProblemOutlined style={{fontSize:'100px', color:'#FF7575'}}/>
                <p className={classes.learnMoreDialogTitle}>
                  {pageText.aboutToDisagree}
                </p>
                <p className={classes.learnMoreDialogDescription} style={{margin: 0}}>
                {pageText.note}
                </p>
                <p className={classes.learnMoreDialogDescription} >
                  {pageText.likeToProceed}
                </p>
                <div className={classes.buttonDiv}> 
                <button data-test-id="yes-button" className={classes.buttonStylesYesNo} onClick={()=> {this.onTerminateClick()}}
                 style={{width: "auto", textDecoration:'none', color:'#10BFB8', borderRadius:'12px',border:'2px #10BFB8 solid',background:'white', }}>
                    {pageText.terminate}
                  </button>
                  <button  onClick={()=> {this.setState({terminateAccess:false})}} data-test-id="no-button" className={classes.buttonStylesYesNo}
                  style={{textDecoration:'none', color:'#FF7575', borderRadius:'12px',border:'2px #FF7575 solid',background:'white', }}>
                    {pageText.goBack}
                  </button>
                  </div>
                <button
                  data-test-id="close-button"
                  className={classes.learnMoreDialogCloseButton}
                  onClick={() =>
                    this.setState({terminateAccess:false})
                  }
                >
                  <img src={closeIcon} />
                </button>
              </div>
            </Dialog>
                <FooterBlock />
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start

export default withStyles(webStyles)(CustomTerms);

const DisclaimerButtonBox = styled(Box)({
  boxSizing: "border-box",
  padding: "20px",
  width: "100%",
  display: "flex",
  paddingBottom: "10px",
  justifyContent: "center",
  alignItems: "center",
  "& .discButton": {
    width: "auto",
    padding: "10px",
    fontSize: "24px"
  },
  '& .discButtonSpan': {
    "@media (max-width:600px)": {
      fontSize: "16px"
    }
  }
});

const DisclaimerBox = styled(Box)({
  width: "100%",
  padding: "10px 20px",
  display: "flex",
  boxSizing: "border-box",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "10px",
  "& .discButton": {
    width: "auto",
    fontSize: "24px",
    padding: "10px"
  }
});
// Customizable Area End