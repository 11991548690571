Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.postCheckoutApiEndPoint = 'bx_block_stripe_integration/payments/checkout';
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.getMethod = 'get';
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.subscriptionApiEndPoint = 'bx_block_subscriptions/find_users_plans';
exports.checkSubscriptionApiEndPoint = 'bx_block_subscriptions/find_user_subscription';

exports.postSubscriptionApiEndPoint = 'bx_block_subscriptions/user_subscription_plans';
exports.postCreditCardApiEndPoint = 'bx_block_stripe_integration/payments/initiate_payment';
exports.staticTextArabic = {
  iAmA: "أنا أُريد ...",
  seller: "البائع",
  buyer: "المشتري",
  proceed: "المتابعة",
};
exports.subscriptionEngText = {
  subscriptions: `Subscriptions`,
  buyerSubscribeDetails: "Join the Premium Nami Community<br> Bridge the gap with leading MENA startups and robust lower-middle market companies.",
  anually: `anually`,
  subscribeBtnTxt: "Subscribe Now!",
  subscribedBtnTxt: "Subscribed",
  takeMeBacka: 'No, Take me back',
  renewSub: 'Yes, Renew my subscription',
  expiredText:`Your subscription has expired`,
  childHeading: "Features & Benefits",
  weAcceptFollowingCards: `We accept the following cards`,
  whatYouGet: `Here’s what you get with the subscription`,
  cancelPayment: `Cancel Payment`,
  proceedToPayment: `Proceed to Payment`,
  days: `Days`,
  remaining: `Remaining`,
  accessToken: `Access requests used till now`,
  expiresOn: `Subscription expires on:`,
  active: `Active`,
  expited: `Expired`,
  success: 'Success!',
  failure: 'Payment Failed',
  successText: 'You have been subscribed successfully!',
  close: 'Close'
}
exports.subscriptionArText = {
  subscriptions: `الإشتراكات`,
  buyerSubscribeDetails: "انضم إلى مجتمع نامي بريميوم<br> قم بسد الفجوة مع الشركات الرائدة في منطقة الشرق الأوسط و شمال أفريقيا والشركات القوية في الفئة المتوسطة الدنيا",
  anually: `سنويا`,
  subscribeBtnTxt: "انضم إلينا الآن!",
  subscribedBtnTxt: "مشترك",
  takeMeBacka: 'عودة',
  renewSub: ' جدد اشتراكي',
  expiredText:`لقد انتهت مدة اشتراكك`,
  childHeading: " الميزات و الفوائد",
  weAcceptFollowingCards: `نحن نقبل البطاقات التالية`,
  whatYouGet: `ما تحصل عليه مع اشتراكك`,
  cancelPayment: `إلغاء الدفع`,
  proceedToPayment: `Proceed to Payment`,
  days: `الأيام`,
  remaining: `المتبقية`,
  accessToken: `طلبات الوصول المستخدمة حتى الآن`,
  expiresOn: `ينتهي الاشتراك في:`,
  active: `فعال`,
  expited: `منتهي`,
  success: 'نجاح!',
  failure: 'فشل في عملية الدفع',
  successText: 'تم الاشتراك بنجاح!',
  close: 'إغلاق'
}
exports.iAmA = "I am a ...";
exports.seller = "Seller";
exports.buyer = "Buyer";
exports.proceed = "Proceed";
exports.onboardingType = {
  Seller: 'seller',
  Buyer: 'buyer'
}
exports.currencyTxt = "SAR";
exports.subscribeBtnTxt = "Subscribe Now!";
exports.childHeading = "Features & Benefits";

exports.buyerSubscribeDetails = "Join the Premium Nami Community<br> Bridge the gap with leading MENA startups and robust lower-middle market companies."
exports.addRoleEndpoint = "account_block/accounts/user_type";
exports.postMethod = "POST"
exports.putMethod = "PUT"
// Customizable Area End
