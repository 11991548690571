import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { backgroundArrow1, signInIcon } from './assets';
import { MailOutline } from "@material-ui/icons";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <HeaderBlock />
          <Box className="contain" style={styles.backGroundContain} >
            <Container maxWidth="sm" className='sm' style={styles.container}>
              <Box className="containerContent"
                sx={styles.containerContent}
              >
                <ThemeProvider theme={theme}>
                  <Typography style={styles.signInHeading} className="signIn">
                    {this.getContent(this.state.signUpPageText.headerTitle, this.state.signUpPageText.headerTitleArabic)}
                  </Typography>
                </ThemeProvider>

                <Box className='formConatiner' style={styles.formContent}>
                  {this.state.accountNotValid &&
                    (
                      <Box className="errorBox" style={styles.errorBox}>
                        <Typography style={styles.errorText}>
                          {this.state.signUpPageText.wrongEmailIdOrPassword}
                        </Typography>
                      </Box>
                    )
                  }
                  <Box className='formHeaderContainer' style={{ width: '80%', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={styles.fieldBox}>
                      <ThemeProvider theme={theme}>
                        <Typography style={{...styles.fieldHeading, fontWeight: this.getIfCondtionForLang(this.state.language ==="en", 500,550)}}>
                         {this.getContent(this.state.signUpPageText.emailIdText,this.state.signUpPageText.emailIdTextArabic)}
                        </Typography>
                      </ThemeProvider>
                      <Input
                        required={true}
                        onFocus={() => this.setState({ focusEmail: true })}
                        onBlur={() => this.setState({ focusEmail: false })}
                        className="emailField"
                        data-test-id="txtInputEmail"
                        placeholder={"mohammedahmed@domain.com"}
                        value={this.state.email}
                        onChange={(e) => this.setEmail(e.target.value)}
                        style={styles.fieldInput}
                        endAdornment={
                          <InputAdornment position="end">
                            <MailOutline
                              style={{
                                color: "#262626",
                                opacity: (this.state.focusEmail || this.state.email) ? '1' : '0.5',
                              }}
                            />
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            fontFamily: 'Montserrat',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: this.state.email?500:300,
                          },
                        }}
                      />
                    </Box>
                    <Box sx={styles.fieldBox}>
                      <ThemeProvider theme={theme}>
                        <Typography style={{...styles.fieldHeading, fontWeight: this.getIfCondtionForLang(this.state.language ==="en", 500,550)}}>
                          {this.getContent(this.state.signUpPageText.passwordText, this.state.signUpPageText.passwordTextArabic)}
                        </Typography>
                      </ThemeProvider>
                      <Input
                        required
                        data-test-id="txtInputPassword"
                        className='passwordField'
                        onFocus={() => this.setState({ focusPassword: true })}
                        onBlur={() => this.setState({ focusPassword: false })}
                        type={this.state.enablePasswordField?"text":"password"}
                        placeholder={"********"}
                        fullWidth={true}
                        value={this.state.copyPassword} 
                        style={styles.fieldInput}
                        onChange={(e) => this.setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <Visibility style={{
                                  color: "#262626",
                                  opacity: (this.state.focusPassword || this.state.password) ? '1' : '0.5'
                                }} />
                              ) : (
                                <VisibilityOff style={{
                                  color: "#262626",
                                  opacity: (this.state.focusPassword || this.state.password) ? '1' : '0.5'
                                }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            fontFamily: 'Montserrat',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: this.state.password?500:300,
                          },
                        }}
                      />
                    </Box>
                    <Box className='passwordAndEmailContainer' style={styles.passwordAndEmailContainer}>
                      <Box
                        className="forgetPassword"
                        style={{ textDecoration: 'underline' }}
                        data-test-id={"btnForgotPassword"}
                        sx={styles.forgetLinks}
                        onClick={this.handleForgotPassword}
                      >
                        {this.getContent(this.state.signUpPageText.forgotPasswordText, this.state.signUpPageText.forgotPasswordTextArabic)}
                      </Box>
                      <Box
                        className="forgetEmail"
                        style={{ textDecoration: 'underline' }}
                        data-test-id={"btnForgotEmail"}
                        sx={styles.forgetLinks}
                        onClick={this.handleForgotEmail}
                      >
                        {this.getContent(this.state.signUpPageText.forgotEmailIdText,this.state.signUpPageText.forgotEmailIdTextArabic)}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="formFooterContainer">
                    <Box
                      className="loginButton"
                      sx={styles.loginButtonBox}
                    >
                      <Button
                        disabled={!this.handleSignInColor()}
                        data-test-id={"btnEmailLogIn"}
                        variant="contained"
                        className="signIN"
                        color="primary"
                        fullWidth
                        onClick={(e) => this.doLogIn(e)}
                        style={{ borderRadius: '24px', backgroundColor: this.handleSignInColor() ? '#10BFB8' : '#8C8C8C', width: '200px', height: '48px' }}
                      >
                        <span className="signInButtonText" style={styles.signInButtonText}>{this.getContent(this.state.signUpPageText.signInText,this.state.signUpPageText.signInTextArabic)}</span>
                      </Button>
                    </Box>
                    <Box
                      sx={styles.newUserBox}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography style={styles.newUserText}>
                          {this.getContent(this.state.signUpPageText.adduserText, this.state.signUpPageText.addNewUserTextArabic)}
                        </Typography>
                      </ThemeProvider>
                    </Box>
                    <Box
                      sx={styles.signUpBox}
                    >
                      <Button
                        data-test-id="btnSignUp"
                        className="signUP"
                        variant="contained"
                        fullWidth
                        style={styles.signUpButton}
                        onClick={() => this.doSignUp()}
                        startIcon={<img src={signInIcon} />}

                      >
                        <span style={styles.signupColor}>{this.getContent(this.state.signUpPageText.signUpText,this.state.signUpPageText.signUpTextArabic)}</span>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const styles: any = {
  backGroundContain: {
    backgroundImage: `url(${backgroundArrow1})`,
    backgroundSize: "min(90%, 1350px) 1270px",
    backgroundPosition:"top center",
    height: "100%",
    backgroundRepeat: "no-repeat",
    margin: 0,
    padding: "5rem 1rem",
  },
  container: {
    maxWidth: "550px",
    paddingTop: '1%',
    height: '100%'
  },
  containerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "18px",
    paddingBottom: "30px",
    border: '1px solid #262626',
    borderRadius: '20px',
    backgroundColor: 'rgba(255,255,255,1)',
  },
  signInHeading: {
    fontSize: '25px',
    color: '#5412FC',
    fontStyle: 'normal',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    paddingBottom: '10px'
  },
  formContent: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  errorBox: {
    width: '80%',
    height: '24px',
    borderRadius: '12px',
    background: 'rgba(255, 117, 117, 0.30)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorText: {
    fontWeight: 700,
    fontSize: '10px',
    color: '#FF7575',
    textAlign: 'center',
    fontFamily: 'Montserrat'
  },
  fieldBox: {
    width: '100%',
    padding: "2% 0px"
  },
  fieldHeading: {
    color: '#000',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: 'Montserrat'
  },
  fieldInput: {
    width: '100%',
    padding: "10px",
    borderRadius: '12px',
    border: '1px solid rgb(140,140,140)',
  },
  passwordAndEmailContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  forgetLinks: {
    color: '#AAA',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    cursor: 'pointer'
  },
  loginButtonBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "12%"
  },
  signInButtonText: {
    color: 'white',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Montserrat'
  },
  newUserBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: '5%'
  },
  newUserText: {
    color: '#AAA',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Montserrat'
  },
  signUpBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: '2%'
  },
  signUpButton: {
    backgroundColor: 'white',
    fontSize: '20px',
    fontFamily: 'Montserrat',
    borderRadius: '30px',
    border: '2px solid #10BFB8',
    width: '200px',
    height: '50px'
  },
  signupColor: {
    color: '#10BFB8' 
  }

}
// Customizable Area End
