import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ToastPosition, toast } from "react-toastify";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { conditionSort } from "../../../components/src/Helper";
const fetch = require('node-fetch');
export interface ListingsCards {
  id: string;
  title: string;
  titleImgSrc: any;
  type: string;
  description: string;
  annualRevenue: string;
  employeesCount: number;
  annualProfit: string;
  foundedIn: string;
  basedIn: string;
  proposedValuation: string;
  originListing: string;
  is_bookmarked: boolean | null;
  isDealClosed: boolean;
  requestInitiated: boolean;
  is_flag:boolean;
}

export interface IStartUpPageText {
  privateSubHeading: string;
  CompleteText: string;
  completeYourProfileFullText: string;
  needHelpText: string;
  exploreListingText: string;
  completeYourProfileText:string;
  PrivateInformationHeadingText: string;
  otherCatText: string;
  headingText:string;
  subHeadingText:string;
  myListingText: string;
  nameOfTheStartupText : string;
  startUpWebsiteText:string;
  viewFullProfileLink :string;
  startUpNameText:string;
  whoAreYourCompitatorsText:string;
  autoSignInLabelNda:string;
  pAndLStatmentText:string;
  uploadDocumentText:string;
  documentsAndFilesText:string;
  dateFoundedText:string;
  SaudiArabiaText:string;
  startUpTeamSizeText:string;
  startUpTypeText:string;
  QatarText:string;
  autoSignAcceptText:string;
  autoSignText:string;
  BahrainText:string;
  KuwaitText:string;
  OmanText:string;
  EgyptText:string;
  uaeText:string;
  businessModelText:string;
  countryText:string;
  CityText:string;
  DescriptionText:string;
  AskingPriceText:string;
  AskingPriceReasonText:string;
  companyOverViewText:string;
  AcquisitionText:string;
  summaryText:string;
  subheadingForAllText :string;
  techStackText:string;
  thisFieldIsRequiredText:string;
  listOfProductsText:string;
  growthOppText:string;
  reasonForSellingText:string;
  finanacialSources:string;
  ltmGrassRevenueText:string;
  ltmGrassToolTIpText:string;
  clearText:string;
  ltmNetProfitText:string;
  ltmNetProfitToolTipText:string;
  annualRecurringRevenueText:string;
  otherText:string;
  lastMonthNetprofitToolTipTextOne:string;
  annualRecurringToolTipText:string;
  annualGrowthRateText:string;
  keyAssestsText:string;
  annualGrowthRateToolTipText:string;
  lastMonthGrassRevenueText:string;
  lastMonthGrassRevenueToolTipText:string;
  revenueToolTipHeadingTextOne:string;
  lastMonthNetprofitText:string;
  lastMonthNetprofitToolTipTextThree:string;
  successConfirmTextLine1: string;
  multipleTypeText:string;
  revenueToolTipContentTextone:string;
  arrMultipleTypeHeading:string;
  arrMultipleContentText:string;
  lastMonthNetprofitToolTipTextTow:string;
  successConfirmTextLine2: string;
  multipleNumberText:string;
  arrStringValue:string;
  numberOfUsersText:string;
  successConfirmTextLine3: string;
  addNewListingText:string;
  startUpText:string;
  saveText:string;
  businessText:string;
  nextText:string;
  successText:string;
  listingSuccessFullyAddedText:string;
  youCanAccessText:string;
  successMessageToastText:string;
  pleaseCreateNewListing:string;
  successConfirmTextLine4: string;
  businessContent:string;
  uploadDocumentFileText:string;
  titleText:string;
  addNewListingAfterText:string;
  addingNewListingCompleteText: string;
  financialHeadingText:string;
  startUpContent:string;
  smeText:string;
  revenueMultipleText:string;
  customerMetricsHeadingText:string;
 
}

interface IResponseUser {
  id: string;
  type: string;
  attributes: {
    name: string;
    arabic_name: string;
    account_id: number;
    closed: boolean;
    image: string;
    category: {
      id: string;
      type: string;
      attributes: {
        id: number;
        name: string;
        arabic_category: string;
        logo: {
          url: string;
        };
        dark_icon: null;
        dark_icon_active: null;
        dark_icon_inactive: null;
        light_icon: string;
        light_icon_active: null;
        light_icon_inactive: null;
        rank: number;
        created_at: string;
        updated_at: string;
        selected_sub_categories: null;
      }
    };
    sub_category: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
      parent_id: null;
      rank: number;
      arabic_name: string;
    };
    deal_initiated: boolean;
    description: string;
    arabic_description: string;
    summarry: {
      city: string;
      country: string;
    };
    arabic_summary: {
      city: string;
      country: string;
    };
    proposed_valuation: string;
    number_of_employees: number;
    date_founded: string;
    annual_recurring_revenue: string;
    ltm_net_profit: string;
    preffered_language: string;
    translated_preferred_language: string;
    is_bookmarked: boolean;
    archived_at: null;
    is_flag: boolean;
}
}
export interface FilterSortType {
  label: string;
  id: string;
}

export interface CountryOption {
  icon: string;
  country: string;
  cities: string[];
}

interface CatalogueList {
  id: string,
            title: string,
            titleImgSrc: string | null,
            type: string,
            description: string,
            annualRevenue:string,
            employeesCount: number,
            annualProfit: string,
            foundedIn: string,
            basedIn: string,
            is_bookmarked: boolean,
            proposedValuation: string,
            originListing: string,
            isDealClosed: boolean,
            requestInitiated: boolean,
            is_flag:boolean;
}



export interface SortByType {
  name: string;
  id: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  expand: string;
  select: string;
  listings: string;
  loading: boolean;
  listingsCards: ListingsCards[];
  catalogueListForThreeCard: ListingsCards[][];
  anchorEl: null | HTMLElement;
  filterType: string;
  searchKey: string;
  categoriesList: {
    id: string;
    name: string;
    subCategories: { id: string; name: string; isChecked: boolean, image: {url: string, id: string} }[];
  }[];
  filterSortBy: string;
  priceRangeValue: any;
  priceLimit: any;
  filterRange: any;
  priceGraph: any;
  filters: any;
  startupName: string;
  listingStatus: string;
  startupWebsite: string;
  competitors: string[];
  plStatement: any;
  documentsFiles: boolean;
  autoSignNDA: boolean;
  autoAcceptRequest: boolean;
  dateFounded: string;
  startupTeamSize: string;
  startupTypeCategory: string;
  country: string;
  city: string;
  headline: string;
  otherCatText: string;
  description: string;
  askingPrice: string;
  askingPriceReasoning: string;
  businessModelAndPricing: string;
  techStack: string;
  growthOpportunity: string[];
  keyAssets: string[];
  reasonForSelling: string;
  financialSources: string[];
  ltmgross: string;
  ltmnet: string;
  annualRecurringRevenue: string;
  annualGrowthRate: string;
  lastMonthGrossRevenue: string;
  lastMonthNetProfit: string;
  multipleType: string;
  summaryError: {
    dateFounded: boolean;
    startupTeamSize: boolean;
    startupTypeCategory: boolean;
    country: boolean;
    city: boolean;
    headline: boolean;
    otherCatText: boolean;
    description: boolean;
    askingPrice: boolean;
    askingPriceReasoning: boolean;

}
privateInfoError:{
  startupName: boolean;
  startupWebsite: boolean;
  competitors: boolean;
  plStatement: boolean;
};
  companyError: {
    businessModelAndPricing : boolean;
    techStack :boolean;
    growthOpportunity :boolean;
    keyAssets :boolean;
  };
  financialError:{
    grossRevenue:boolean;
    netProfit:boolean;
    annualRevenue:boolean;
    annualGrowthRate:boolean;
    grassRevenue:boolean;
    lastMonthNetProfit:boolean;
  }
  privateInfoFlag:boolean;
  summaryFlag:boolean;
  companyFlag:boolean;
  financeFlag:boolean;
  matricsFlag:boolean;
  acquisitionError: boolean;
  metricError: {
    numberOfUsers:boolean;
  };
  multipleNumber: undefined | string;
  arrayFiles: { title: string; uploadedFile: any }[];
  modalFile: any;
  modalFileTitle: string;
  openFileModal: boolean;
  numberOfUsers: number | string;
  growthOpputunityOther: boolean;
  growOppurtunityOtherValue: string;
  keyAssestsOther: boolean;
  keyAssestsOtherValue: string;
  financialSoucesOther: boolean;
  financialSoucesValue: string;
  products: string[];
  ancharEl: any;
  openBuisnessBox: boolean;
  openStartUpBox: boolean;
  listId: undefined | number;
  list: any[];
  progress: string;
  boxTitle: string;
  boxContent: string;
  isListingModal: boolean;
  isListingModalSuccess: boolean;
  AddListingButton: boolean;
  filterSelectionType: FilterSortType[];
  sortByData: SortByType[];
  pageTxtData: any;
  prfileImagePath: string;
  nameOftheUser: string;
  profile_percent: string;
  cataloguePageText :{
    businessText: string;
    startUpText: string;
    AnnualRevenueText: string;
    annualProfitText: string;
    basedInText: string;
    numberOfEmployeesText: string;
    foundedInText: string;
    proposedEvalutionText: string;
    originalListingText: string;
    bookmarkText: string;
    archiveReportText: string;
    shareListText: string;
    unBookMarkText: string;
    archiveListingText: string;
    yesText: string;
    noText: string;
    ArchiveText: string;
    chooseThisWillText: string;
    hideTheListingText: string;
    reportText: string;
    flagTheListing: string;
    viaLinkText: string;
    TwitterText: string;
    faceBookText: string;
    instragramText: string;
    whatsAppText: string;
    bookmarkModalTitle: string;
    addListTxt: string;
    shareBoxTitle: string;
    flagBoxTitle: string;
    flagBoxDetails: string;
    reportBlogTitle: string;
    listTitleTextField:string;
    LinkedInText:string;
    maximumText:string;
    MinimumText:string;
    annualGrowthRateCatText:string;
    annualRecurringText:string;
    startupAgeText: string;
    numberOfCustomersText:string;
    clearAllFilters:string;
    clearAllFiltersAr:string;
    copiedTxtMsg:string;
    dealClosedText: string;
    }

  language:string;
  otherSubCatId: string;
  selectedMenuList : {icon:string,value:string,title:string}[];
  citiesData : {country:string, cities: string[]}[];
  growthAppartunitiesValues:{title:string, value:string}[];
  keyAssestsValues:{title: string, value:string}[];
  financialSoucesValues:{title: string, value:string}[];
  revenueMultipleType: {title:string,value:string}[];
  startUpPageText : {
    privateSubHeading: string;
    otherCatText: string;
    nameOfTheStartupText : string;
    CompleteText: string;
    myListingText: string;
    completeYourProfileFullText: string;
    needHelpText: string;
    completeYourProfileText:string;
    exploreListingText: string;
    PrivateInformationHeadingText: string;
    subHeadingText:string;
    viewFullProfileLink :string;
    startUpNameText:string;
    startUpWebsiteText:string;
    whoAreYourCompitatorsText:string;
    pAndLStatmentText:string;
    uploadDocumentText:string;
    autoSignText:string;
    autoSignInLabelNda:string;
    autoSignAcceptText:string;
    documentsAndFilesText:string;
    dateFoundedText:string;
    startUpTeamSizeText:string;
    startUpTypeText:string;
    countryText:string;
    BahrainText:string;
    EgyptText:string;
    KuwaitText:string;
    OmanText:string;
    QatarText:string;
    SaudiArabiaText:string;
    uaeText:string;
    CityText:string;
    headingText:string;
    DescriptionText:string;
     AskingPriceText:string;
    AskingPriceReasonText:string;
    thisFieldIsRequiredText:string;
    companyOverViewText:string;
    AcquisitionText:string;
    summaryText:string;
    subheadingForAllText :string;
    businessModelText:string;
    techStackText:string;
    listOfProductsText:string;
    growthOppText:string;
    keyAssestsText:string;
    reasonForSellingText:string;
    finanacialSources:string;
    ltmGrassRevenueText:string;
    ltmGrassToolTIpText:string;
    ltmNetProfitText:string;
    ltmNetProfitToolTipText:string;
    annualRecurringRevenueText:string;
    annualRecurringToolTipText:string;
    annualGrowthRateText:string;
    annualGrowthRateToolTipText:string;
    lastMonthGrassRevenueText:string;
    lastMonthGrassRevenueToolTipText:string;
    lastMonthNetprofitText:string;
    lastMonthNetprofitToolTipTextOne:string;
    lastMonthNetprofitToolTipTextTow:string;
    lastMonthNetprofitToolTipTextThree:string;
    multipleTypeText:string;
    revenueToolTipHeadingTextOne:string;
    revenueToolTipContentTextone:string;
    arrMultipleTypeHeading:string;
    arrMultipleContentText:string;
    multipleNumberText:string;
    numberOfUsersText:string;
    saveText:string;
    clearText:string;
    addNewListingText:string;
    startUpText:string;
    businessText:string;
    nextText:string;
    successText:string;
    listingSuccessFullyAddedText:string;
    youCanAccessText:string;
    uploadDocumentFileText:string;
    titleText:string;
    addNewListingAfterText:string;
    addingNewListingCompleteText: string;
    otherText:string;
    financialHeadingText:string;
    customerMetricsHeadingText:string;
    businessContent:string;
    startUpContent:string;
    smeText:string;
    revenueMultipleText:string;
    arrStringValue:string;
    successMessageToastText:string;
    pleaseCreateNewListing:string;
    successConfirmTextLine1: string;
    successConfirmTextLine2: string;
    successConfirmTextLine3: string;
    successConfirmTextLine4:string;
  };
  successStartUpModal: boolean;
  hashId:string;
  categoryIdNew:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      expand: '',
      select: "",
      listings: "1",
      loading: false,
      listingsCards: [],
      catalogueListForThreeCard: [],
      anchorEl: null,
      filterType: "",
      categoriesList: [],
      filterSortBy: "price_low_to_high",
      searchKey: "",
      priceLimit: [3000, 40000],
      priceRangeValue: [3000, 40000],
      citiesData:[],
      filterRange: [
        { values: `10 - 100`, isChecked: false },
        { values: `100 - 1000`, isChecked: false },
        { values: `1000 - 10000`, isChecked: false },
        { values: `10000 - 100000`, isChecked: false },
        { values: `100000 - 1000000`, isChecked: false },
        { values: `More then 1000000`, isChecked: false },
      ],
      priceGraph: [
        ["Range", "Catalogue"],
        ["0", 0],
        ["100", 20],
        ["200", 120],
        ["300", 140],
        ["400", 80],
        ["500", 90],
        ["600", 350],
        ["700", 350],
        ["800", 140],
        ["900", 20],
        ["1000", 20],
      ],
      filters: {
        revenue_multiple: {
          revenueLimit: [3000, 40000],
          values: [3000, 40000],
          marks: [
            { value: 3000, label: "3000" },
            { value: 40000, label: "40000" },
          ],
        },
        ltm_revenue: { ltmLimit: [3000, 40000], values: [3000, 40000] },
        ltm_profit: { ltmProfitLimit: [3000, 40000], values: [3000, 40000] },
        location: { country: "", city: "" },
        annual_revenue: { values: [3000, 40000] },
        growth_rate: { values: [3000, 40000] },
        age: {
          startUpLimit: [0, 100],
          values: [0, 100],
          marks: [
            { value: 0, label: "0" },
            { value: 100, label: "100" },
          ],
        },
        noOfCustomer: { customers: [] },
      },
      startupName: "",
      listingStatus: "",
      startupWebsite: "",
      competitors: ["", "", ""],
      plStatement: null,
      documentsFiles: false,
      autoSignNDA: false,
      autoAcceptRequest: false,
      dateFounded: "",
      startupTeamSize: "",
      startupTypeCategory: "",
      country: "",
      city: "",
      headline: "",
      otherCatText: "",
      description: "",
      askingPrice: "",
      askingPriceReasoning: "",
      businessModelAndPricing: "",
      techStack: "",
      growthOpportunity: [],
      keyAssets: [],
      reasonForSelling: "",
      financialSources: [],
      ltmgross: "",
      ltmnet: "",
      annualRecurringRevenue: "",
      annualGrowthRate: "",
      lastMonthGrossRevenue: "",
      lastMonthNetProfit: "",
      multipleType: "",
      summaryError: {  
    dateFounded: false,
    otherCatText: false,
    startupTeamSize: false,
    startupTypeCategory: false,
    country: false,
    city: false,
    headline: false,
    description: false,
    askingPrice: false,
    askingPriceReasoning: false,
      },
      privateInfoError:{
        startupName: false,
        startupWebsite: false,
        competitors: false,
        plStatement: false,
      },
      companyError: {
        businessModelAndPricing : false,
        techStack :false,
        growthOpportunity :false,
        keyAssets :false,
      },
      financialError: {
        grossRevenue:false,
        netProfit:false,
        annualRevenue:false,
        annualGrowthRate:false,
        grassRevenue:false,
        lastMonthNetProfit:false,
      },
      privateInfoFlag:false,
      summaryFlag:false,
      companyFlag:false,
      financeFlag:false,
      matricsFlag:false,
      acquisitionError: false,
      metricError: {
        numberOfUsers:false
      },
      multipleNumber: undefined,
      arrayFiles: [],
      modalFile: "",
      modalFileTitle: "",
      openFileModal: false,
      numberOfUsers: "",
      growthOpputunityOther: false,
      growOppurtunityOtherValue: "",
      keyAssestsOther: false,
      keyAssestsOtherValue: "",
      financialSoucesOther: false,
      financialSoucesValue: "",
      products: ["", "", ""],
      ancharEl: null,
      openBuisnessBox: false,
      openStartUpBox: false,
      listId: undefined,
      list: [],
      progress: "0",
      boxContent: "",
      boxTitle: "",
      isListingModal: false,
      isListingModalSuccess: false,
      AddListingButton: true,
      filterSelectionType: [],
      sortByData: [],
      language:"en",
      otherSubCatId: "",
      financialSoucesValues: configJSON.financialSources,
      pageTxtData: {
        [configJSON.type]: configJSON.type,
        [configJSON.price]: configJSON.price,
        [configJSON.view]: configJSON.view,
        [configJSON.allFilters]: configJSON.allFilters,
        [configJSON.sortBy]: configJSON.sortBy,
        clearTxt: configJSON.clearTxt,
        searchTxt: configJSON.searchTxt,
        noRecFoundTxt: configJSON.noRecFoundTxt,
        oneListing: configJSON.oneListing,
        threeListing: configJSON.threeListing,
        askingPrice: configJSON.askingPrice,
        minimumTxt: configJSON.minimumTxt,
        maximumTxt: configJSON.maximumTxt,
        ...this.sortByFilterList.reduce((obj: any, item) => {
          obj[item.id] = item.name;
          return obj;
        }, {}),
        revenueMultiple: configJSON.revenueMultiple,
        revenueGross: configJSON.revenueGross,
        grossProfit: configJSON.grossProfit,
        locationTxt: configJSON.locationTxt
      },
      prfileImagePath: "",
      nameOftheUser: "",
      profile_percent: "",
      cataloguePageText :{
        businessText: configJSON.businessText,
        dealClosedText: configJSON.dealClosedText,
        startUpText: configJSON.startUpText,
        AnnualRevenueText: configJSON.AnnualRevenueText,
        annualProfitText: configJSON.annualProfitText,
        basedInText: configJSON.basedInText,
        numberOfEmployeesText: configJSON.numberOfEmployeesText,
        foundedInText: configJSON.foundedInText,
        proposedEvalutionText: configJSON.proposedEvalutionText,
        originalListingText: configJSON.originalListingText,
        bookmarkText: configJSON.bookmarkText,
        archiveReportText: configJSON.archiveReportText,
        shareListText: configJSON.shareListText,
        unBookMarkText: configJSON.unBookMarkText,
        archiveListingText: configJSON.archiveListingText,
        yesText: configJSON.yesText,
        noText: configJSON.noText,
        ArchiveText: configJSON.ArchiveText,
        chooseThisWillText: configJSON.chooseThisWillText,
        hideTheListingText: configJSON.hideTheListingText,
        reportText: configJSON.reportText,
        flagTheListing: configJSON.flagTheListing,
        flagBoxTitle: configJSON.flagBoxTitle,
        viaLinkText: configJSON.viaLinkText,
        TwitterText: configJSON.TwitterText,
        faceBookText: configJSON.faceBookText,
        instragramText: configJSON.instragramText,
        whatsAppText: configJSON.whatsAppText,
        bookmarkModalTitle: configJSON.bookmarkModalTitle,
        addListTxt: configJSON.addListTxt,
        shareBoxTitle: configJSON.shareBoxTitle,
        flagBoxDetails: configJSON.flagBoxDetails,
        reportBlogTitle: configJSON.reportBlogTitle,
        listTitleTextField: "listTitle",
        LinkedInText:"LinkedIn",
        maximumText: configJSON.maximumText,
        MinimumText: configJSON.MinimumText,
        annualGrowthRateCatText: configJSON.annualGrowthRateCatText,
        annualRecurringText:configJSON.annualRecurringText,
        startupAgeText:configJSON.startupAgeText,
        numberOfCustomersText:configJSON.numberOfCustomersText,
        clearAllFilters: "All filters cleared",
        clearAllFiltersAr: "تم مسح جميع المرشحات",
        copiedTxtMsg:configJSON.copiedTxtMsg,
      },

      growthAppartunitiesValues: configJSON.growthOpportunities,
      keyAssestsValues: configJSON.keyAssets,
      selectedMenuList : configJSON.selectedMenuList,
      revenueMultipleType:[],
      startUpPageText : {
        privateSubHeading: configJSON.privateSubHeading,
        otherCatText: configJSON.otherCatText,
        nameOfTheStartupText : configJSON.nameOfTheStartupText,
        CompleteText: configJSON.CompleteText,
        myListingText:configJSON.myListingText,
        completeYourProfileFullText: configJSON.completeYourProfileFullText ,
        needHelpText: configJSON.needHelpText,
        completeYourProfileText: configJSON.completeYourProfileText,
        exploreListingText: configJSON.exploreListingText,
        PrivateInformationHeadingText: configJSON.PrivateInformationHeadingText,
        subHeadingText: configJSON.subHeadingText,
        viewFullProfileLink : configJSON.viewFullProfileLink,
        startUpNameText:configJSON.startUpNameText,
        startUpWebsiteText: configJSON.startUpWebsiteText,
        whoAreYourCompitatorsText:configJSON.whoAreYourCompitatorsText,
        pAndLStatmentText:configJSON.pAndLStatmentText,
        uploadDocumentText: configJSON.uploadDocumentText,
        autoSignText: configJSON.autoSignText,
        autoSignInLabelNda: configJSON.autoSignInLabelNda,
        autoSignAcceptText: configJSON.autoSignAcceptText,
        documentsAndFilesText:configJSON.documentsAndFilesText,
        dateFoundedText: configJSON.dateFoundedText,
        startUpTeamSizeText: configJSON.startUpTeamSizeText,
        startUpTypeText: configJSON.startUpTypeText,
        countryText: configJSON.countryText,
        BahrainText: configJSON.BahrainText,
        EgyptText: configJSON.EgyptText,
        KuwaitText: configJSON.KuwaitText,
        OmanText: configJSON.OmanText,
        QatarText: configJSON.QatarText,
        SaudiArabiaText: configJSON.SaudiArabiaText,
        uaeText: configJSON.uaeText,
        CityText: configJSON.CityText,
        headingText: configJSON.headingText,
        DescriptionText: configJSON.DescriptionText,
        AskingPriceText: configJSON.AskingPriceText,
        AskingPriceReasonText: configJSON.AskingPriceReasonText,
        thisFieldIsRequiredText: configJSON.thisFieldIsRequiredText,
        companyOverViewText:configJSON.companyOverViewText,
        AcquisitionText:configJSON.AcquisitionText,
        summaryText:configJSON.summaryText,
        subheadingForAllText :configJSON.subheadingForAllText,
        businessModelText:configJSON.businessModelText,
        techStackText:configJSON.techStackText,
        listOfProductsText:configJSON.listOfProductsText,
        growthOppText:configJSON.growthOppText,
        keyAssestsText:configJSON.keyAssestsText,
        reasonForSellingText:configJSON.reasonForSellingText,
        finanacialSources:configJSON.finanacialSources,
        ltmGrassRevenueText:configJSON.ltmGrassRevenueText,
        ltmGrassToolTIpText:configJSON.ltmGrassToolTIpText,
        ltmNetProfitText:configJSON.ltmNetProfitText,
        ltmNetProfitToolTipText:configJSON.ltmNetProfitToolTipText,
        annualRecurringRevenueText:configJSON.annualRecurringRevenueText,
        annualRecurringToolTipText:configJSON.annualRecurringToolTipText,
        annualGrowthRateText:configJSON.annualGrowthRateText,
        annualGrowthRateToolTipText:configJSON.annualGrowthRateToolTipText,
        lastMonthGrassRevenueText:configJSON.lastMonthGrassRevenueText,
        lastMonthGrassRevenueToolTipText:configJSON.lastMonthGrassRevenueToolTipText,
        lastMonthNetprofitText:configJSON.lastMonthNetprofitText,
        lastMonthNetprofitToolTipTextOne:configJSON.lastMonthNetprofitToolTipTextOne,
        lastMonthNetprofitToolTipTextTow:configJSON.lastMonthNetprofitToolTipTextTow,
        lastMonthNetprofitToolTipTextThree:configJSON.lastMonthNetprofitToolTipTextThree,
        multipleTypeText:configJSON.multipleTypeText,
        revenueToolTipHeadingTextOne:configJSON.revenueToolTipHeadingTextOne,
        revenueToolTipContentTextone:configJSON.revenueToolTipContentTextone,
        arrMultipleTypeHeading:configJSON.arrMultipleTypeHeading,
        arrMultipleContentText:configJSON.arrMultipleContentText,
        multipleNumberText:configJSON.multipleNumberText,
        numberOfUsersText:configJSON.numberOfUsersText,
        saveText:configJSON.saveText,
        clearText:configJSON.clearText,
        addNewListingText:configJSON.addNewListingText,
        startUpText:configJSON.startUpText,
        businessText:configJSON.businessText,
        nextText:configJSON.nextText,
        successText:configJSON.successText,
        listingSuccessFullyAddedText:configJSON.listingSuccessFullyAddedText,
        youCanAccessText:configJSON.youCanAccessText,
        uploadDocumentFileText:configJSON.uploadDocumentFileText,
        titleText:configJSON.titleText,
        addNewListingAfterText:configJSON.addNewListingAfterText,
        addingNewListingCompleteText: configJSON.addingNewListingCompleteText,
        otherText:configJSON.otherText,
        financialHeadingText:"Financials",
        customerMetricsHeadingText:"Customer & Vendor Metrics",
        businessContent: configJSON.businessContent,
        startUpContent: configJSON.startUpContent,
        smeText: configJSON.smeText,
        revenueMultipleText: configJSON.revenueMultiple,
arrStringValue: configJSON.arrStringValue,
successMessageToastText:configJSON.successMessageToastText,
pleaseCreateNewListing:configJSON.pleaseCreateNewListing,
successConfirmTextLine1: configJSON.successConfirmTextLine1,
successConfirmTextLine2: configJSON.successConfirmTextLine2,
successConfirmTextLine3: configJSON.successConfirmTextLine3,
successConfirmTextLine4: configJSON.successConfirmTextLine4,
    
      },
    successStartUpModal: false,
    hashId:"",
    categoryIdNew:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.applyTranslation();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = await getStorageData("token");
      this.receiveTokenValue(token);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseAPIId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getCatalogueAPI === responseAPIId) {
        this.receiveCatalogueAPI(responseJson);
      }
      if (this.getCategoriesAPI === responseAPIId) {
        this.receiveCategoriesAPI(responseJson);
      }
      if (this.getListsId === responseAPIId) {
        this.updateProgressOnEachTime(responseJson);
      }
      if (this.getListId === responseAPIId) {
        this.getListResponse(responseJson);
      }
      this.handleApis(responseAPIId, responseJson);
    }
    this.handleResForCreateListing(from, message);
    this.handleResAddSubCat(from, message);
    this.handleResForUpdatingPercentage(from, message);
    this.handleResForUpdateApi(this.updateComapnyId, from, message);
    this.handleResForUpdateApi(this.updatePrivateInformationId, from, message);
    this.handleResForUpdateApi(this.updateSummaryDetailsId, from, message);
    this.handleResForUpdateApi(this.updateMetricInformationId, from, message);
    this.handleResForUpdateApi(
      this.updateFinancialInformationId,
      from,
      message
    );
    this.handleResForUpdateApi(this.updateAcquisitionDetailsId, from, message);

    // Customizable Area End
  }

  // Customizable Area Start
  getCatalogueAPI = "";
  getCategoriesAPI = "";
  updateSummaryDetailsId = "";
  postNewCategoryAPIId = "";
  updatePrivateInformationId = "";
  updateFinancialInformationId = "";
  updateMetricInformationId = "";
  updateComapnyId = "";
  getListsId = "";
  updateAcquisitionDetailsId = "";
  getListId = "";
  getCompayOverViewId = "";
  getPrivateInformationId = "";
  getProfileInformation = "";
  createListsCallId = "";
  updateListsCallId="";
  countriesData: CountryOption[] = [
    {icon: "https://flagcdn.com/sa.svg",country: "Saudi Arabia",cities: [ "Jubail", "Taif", "Mecca", "Medina", "Riyadh", "Jeddah", "Khobar", "Dhahran", "Dammam","Buraydah",],},
    {icon: "https://flagcdn.com/om.svg",country: "Oman",cities: ["Sohar","As Suwayq","Ibri","Saham","Barka","Rustaq","Muscat","Seeb","Salalah","Bawshar"],},
    {icon: "https://flagcdn.com/ae.svg",country: "United Arab Emirates",cities: ["Dubai","Abu Dhabi","Umm Al-Quwain","Ras Al Khaimah","Sharjah","Al Ain","Ajman","Fujairah",],},
    {icon: "https://flagcdn.com/qa.svg",country: "Qatar",cities: ["Doha","Al Wakrah","Umm Salal Ali","Al Rayyan","Al Khor","Mesaieed","Madinat ash Shamal","Umm Salal Muhammad",],    },   
    {icon: "https://flagcdn.com/kw.svg",country: "Kuwait",cities: ["Kuwait City","Hawalli","Salmiya","Fintas","Jahra","Sabah Al Salem","Al Ahmadi","Mubarak Al-Kabeer","Farwaniya",]},   
    {icon: "https://flagcdn.com/bh.svg",country: "Bahrain",cities:  [ "Riffa", "Muharraq", "Hamad Town", "Manama", "A'ali", "Isa Town", "Sitra", "Budaiya",]},
    {icon: "https://flagcdn.com/eg.svg",country: "Egypt",cities: ["Cairo",   "Alexandria","Suez","Luxor","Asyut","Ismailia","Tanta","Giza","Shubra El Kheima","Port Said",]},   
  ];
  selections = [    
    configJSON.type,  configJSON.price,configJSON.view,configJSON.allFilters,configJSON.sortBy,
  ];
  sortByFilterList = [
    { id: "price_low_to_high", name: "Price: Low to High" },
    { id: "price_high_to_low", name: "Price: High to Low" },
    { id: "a_to_z", name: "A to Z" },
    { id: "date_oldest", name: "Date Listed: Oldest" },
    { id: "date_newest", name: "Date Listed: Newest" },
  ];

  handleCloseModal = () => {
    this.setState({successStartUpModal:false, isListingModal:false})
  }
  handleDropdownSelect = (option: string) => {    
    this.setState(
      {
        listings: option,
      },
      () => this.clearFilterMenu()
    );
  };

  getContent = (englishContent: string | IStartUpPageText | {title:string, value:string}[], 
    arabicContent: string | IStartUpPageText | {title:string, value:string}[]) => {
    return this.state.language === "en" ? englishContent : arabicContent;
  }

  getLabel = (name:string) => {
    return name === "SME" ? this.getSpecifiedLangString(this.state.language, this.state.cataloguePageText.businessText, configJSON.businessTextArabicNew) : this.getSpecifiedLangString(this.state.language, this.state.cataloguePageText.startUpText, configJSON.startUpTextArabic )
  }

  navigateToSignUp = () => {
    removeStorageData('token')
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  handleFilterMenuClick = (event: any, selection: string) => {
    this.setState({ select: selection, anchorEl: event.currentTarget });
  };

  clearFilterMenu = () => {
    this.setState({ select: "", anchorEl: null });
  };

  handleAccordClick = (key: string) => {
    if(key === this.state.expand){
      this.setState({
        expand: ''
      })
    }else{
      this.setState({
        expand: key
      });
    }
  }

  clearAllFilters = () => {
    const { filters } = this.state;
    filters.revenue_multiple.values = [3000, 40000];
    filters.ltm_revenue.values = [3000, 40000];
    filters.ltm_profit.values = [3000, 40000];
    filters.location.country = "";
    filters.location.city = "";
    filters.annual_revenue.values = [3000, 40000];
    filters.growth_rate.values = [3000, 40000];
    filters.age.values = [0, 100];
    filters.noOfCustomer.customers = [];

    this.setState(
      {
        searchKey: "",
        filterType: "",
        categoriesList: [],
        priceRangeValue: [3000, 40000],
        filterSortBy: "price_low_to_high",
        filterRange: [
          { values: `10 - 100`, isChecked: false },
          { values: `100 - 1000`, isChecked: false },
          { values: `1000 - 10000`, isChecked: false },
          { values: `10000 - 100000`, isChecked: false },
          { values: `100000 - 1000000`, isChecked: false },
          { values: `More then 1000000`, isChecked: false },
        ],
        listings: "1",
        filters: filters,
      },
      () => this.getCatalogueRequest()
    );
    this.getCategoriesRequest();
    toast.success((this.state.language === "en" ? this.state.cataloguePageText.clearAllFilters : this.state.cataloguePageText.clearAllFiltersAr),
      {
            position : this.checkIfCondtionForToast( toast.POSITION.TOP_LEFT , toast.POSITION.TOP_RIGHT),
           style : {
             direction :  this.checkIfConditionForDirection()
        }});
  };

  handleFilterTypeMenu = (event: any) => {
    this.setState(
      {
        filterType: (event.target as HTMLInputElement).value,
      },
      () => this.getCatalogueRequest()
    );
  };

  handleFilterSubCategory = (index: number) => {
    const newSubCategory = [...this.state.categoriesList].map((category) => {
      if (category.id === this.state.filterType) {
        category.subCategories[index].isChecked = !category.subCategories[index]
          .isChecked;
      }
      return category;
    });
    this.setState(
      {
        categoriesList: newSubCategory,
      },
      () => this.getCatalogueRequest()
    );
  };

  handleFilterAllSubCategory = () => {
    const newCategory = [...this.state.categoriesList].map((category) => {
      let newSubCategory = category.subCategories;
      if (category.id === this.state.filterType) {
        const setCheckBox = !category.subCategories.every(
          (sub) => sub.isChecked
        );
        newSubCategory = category.subCategories.map((subCategory) => {
          subCategory.isChecked = setCheckBox;
          return subCategory;
        });
      }
      return { ...category, subCategories: newSubCategory };
    });

    this.setState(
      {
        categoriesList: newCategory,
      },
      () => this.getCatalogueRequest()
    );
  };

  handleFilterSortByMenu = (event: any) => {
    this.setState(
      {
        filterSortBy: (event.target as HTMLInputElement).value,
      },
      () => this.getCatalogueRequest()
    );
  };

  receiveTokenValue = (token: string) => {
    this.setState({ token: token }, () => {
      if (token) {
        this.getCatalogueRequest();
        this.getCategoriesRequest();
      } else {
        this.navigateToSignUp();
      }
    });
  };

  applyTranslation = async () => {
    this.setState({loading: true})
    let lang = await getStorageData('language')
    if (!lang) {
      lang = 'en'
      await setStorageData("language", "en")
    }

    this.setState({ pageTxtData: this.state.pageTxtData, language:lang });
   
    const customLabels = {
      sortBy: this.getSpecifiedLangString(this.state.language, "Sort By", "الترتيب حسب"),
      Price: this.getSpecifiedLangString(this.state.language, "Price", "السعر"),
      Type : this.getSpecifiedLangString(this.state.language,"Type","النوع"),
      View : this.getSpecifiedLangString(this.state.language,"View","طرق العرض"),
      allFilters: this.getSpecifiedLangString(this.state.language,"All Filters","جميع الفلاتر")
    }

    let translateFilterTypes = this.selections.map((val) => {
      if(val ==="Type"){
        return { label: customLabels.Type, id: customLabels.Type  };
      }
      if(val ==="View"){
        return { label: customLabels.View, id: customLabels.View  };
      }
      if(val ==="All Filters"){
        return { label: customLabels.allFilters, id: customLabels.allFilters };
      }
      if(val === "Sort By"){
        return { label: customLabels.sortBy, id: customLabels.sortBy };
      }
      if(val === "Price"){
        return { label: customLabels.Price, id: customLabels.Price };
      }
      return { label: this.state.pageTxtData[val], id: val };
    });

    this.setState({ filterSelectionType: translateFilterTypes });
    this.applyTranslationOfCatalogue(lang);
  }

  handleChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      this.setState({ searchKey: event.target.value }, () => {
        this.getCatalogueRequest();
      });
    }
  };

  handlePriceRangeChange = (event: any, newValue: any) => {
    this.setState({ priceRangeValue: newValue }, () =>
      this.getCatalogueRequest()
    );
  };

  handlePriceRanges = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { priceRangeValue } = this.state;
    if (event.target.name === "minimum") {
      priceRangeValue[0] = Math.min(parseInt(event.target.value));
    } else {
      priceRangeValue[1] = Math.max(parseInt(event.target.value));
    }
    this.setState({ priceRangeValue: priceRangeValue }, () =>
      this.getCatalogueRequest()
    );
  };

  handleFilterPrice = (index: number) => {
    const { filters } = this.state;
    const newFilter = [...this.state.filterRange].map((item, currentIndex) => {
      if (index === currentIndex) {
        item.isChecked = !item.isChecked;
        if (item.isChecked) {
          filters.noOfCustomer.customers.push([item.values]);
        } else {
          const indexToRemove = filters.noOfCustomer.customers.findIndex(
            (customer: any) => customer[0] === item.values
          );
          if (indexToRemove !== -1) {
            filters.noOfCustomer.customers.splice(indexToRemove, 1);
          }
        }
      }
      return item;
    });
    this.setState({ filterRange: newFilter, filters: filters }, () =>
      this.getCatalogueRequest()
    );
  };

  handleAllFilterPriceSelect = () => {
    const { filters } = this.state;
    const allChecked = this.state.filterRange.every(
      (item: any) => item.isChecked
    );

    const newFilter = [...this.state.filterRange].map((item) => {
      item.isChecked = !allChecked;
      return item;
    });

    if (!allChecked) {
      const allValues = this.state.filterRange.map((item: any) => [
        item.values,
      ]); // Converting each value to array
      filters.noOfCustomer.customers = allValues;
    } else {
      filters.noOfCustomer.customers = [];
    }
    this.setState({ filterRange: newFilter, filters: filters }, () =>
      this.getCatalogueRequest()
    );
  };

  handleFilters = (event: any, newValue: any, option: string) => {
    const { filters } = this.state;
    filters[option].values = newValue;
    this.setState({ filters: filters }, () => this.getCatalogueRequest());
  };

  handleFiltersEvent = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    option: string,
    types: string
  ) => {
    const { filters } = this.state;
    let values = filters[option].values;
    switch (types) {
      case "minimum":
        values[0] = event.target.value;
        break;
      case "maximum":
        values[1] = event.target.value;
        break;
    }
    filters[option].values = values;
    this.setState({ filters: filters }, () => this.getCatalogueRequest());
  };

handleLocationChange = (stateName: string, selectedValue: CountryOption | null) => {
  const { filters } = this.state;
  let newFilter = filters;
  if (stateName === "selectedCountry") {
    // Reset selectedCity when country changes
    newFilter.location.country = selectedValue;
    newFilter.location.city = "";
  } else {
    newFilter.location.city = selectedValue;
  }
  this.setState(
    {
      filters: newFilter,
    },
    () => this.getCatalogueRequest()
  );
};

  handleCityOpen = () => {
    const { filters } = this.state;
    if (filters.location.country === "") {
      return [];
    }
  };

  constructEndpoint = () => {
    let endPoint = `${configJSON.getCatalogueAPIEndPoint}?sort_by=${this.state.filterSortBy}`;

    // Add filters to the endpoint
    endPoint = this.addSearchKeyToEndpoint(endPoint);
    endPoint = this.addPriceRangeToEndpoint(endPoint);
    endPoint = this.addLocationFiltersToEndpoint(endPoint);
    endPoint = this.addRevenueMultipleFiltersToEndpoint(endPoint);
    endPoint = this.addLtmRevenueFiltersToEndpoint(endPoint);
    endPoint = this.addLtmProfitFiltersToEndpoint(endPoint);
    endPoint = this.addAnnualRevenueFiltersToEndpoint(endPoint);
    endPoint = this.addGrowthRateFiltersToEndpoint(endPoint);
    endPoint = this.addAgeFiltersToEndpoint(endPoint);
    endPoint = this.addNumberOfCustomersFiltersToEndpoint(endPoint);

    // Add category and subcategory filters to the endpoint
    endPoint = this.addCategoryAndSubcategoryToEndpoint(endPoint);

    return endPoint;
  };
  addSearchKeyToEndpoint = (endPoint: string) => {
    const { searchKey } = this.state;
    if (!!searchKey) {
      endPoint += `&name=${searchKey}&search=${searchKey}`;
    }
    return endPoint;
  };

  addPriceRangeToEndpoint = (endPoint: string) => {
    const { priceRangeValue } = this.state;
    if (!!priceRangeValue) {
      endPoint += `&minimum_price=${priceRangeValue[0]}&maximum_price=${priceRangeValue[1]}`;
    }
    return endPoint;
  };
  addLocationFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.location.country) {
      endPoint += `&country=${filters.location.country.country}`;
    }
    if (!!filters.location.city) {
      endPoint += `&city=${filters.location.city}`;
    }
    return endPoint;
  };

  addRevenueMultipleFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.revenue_multiple.revenueLimit) {
      endPoint += `&from_revenue_multiple=${filters.revenue_multiple.values[0]}&to_revenue_multiple=${filters.revenue_multiple.values[1]}`;
    }
    return endPoint;
  };

  addLtmRevenueFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.ltm_revenue.ltmLimit) {
      endPoint += `&minimum_ltm_revenue=${filters.ltm_revenue.values[0]}&maximum_ltm_revenue=${filters.ltm_revenue.values[1]}`;
    }
    return endPoint;
  };

  addLtmProfitFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.ltm_profit.ltmProfitLimit) {
      endPoint += `&minimum_ltm_profit=${filters.ltm_profit.values[0]}&maximum_ltm_profit=${filters.ltm_profit.values[1]}`;
    }
    return endPoint;
  };

  addAnnualRevenueFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.annual_revenue) {
      endPoint += `&minimum_annual_revenue=${filters.annual_revenue.values[0]}&maximum_annual_revenue=${filters.annual_revenue.values[1]}`;
    }
    return endPoint;
  };

  addAgeFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.age.startUpLimit) {
      endPoint += `&from_age=${filters.age.values[0]}&to_age=${filters.age.values[1]}`;
    }
    return endPoint;
  };

  addNumberOfCustomersFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.noOfCustomer.customers.length) {
      endPoint += `&customers_range=${filters.noOfCustomer.customers}`;
    }
    return endPoint;
  };

  addGrowthRateFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.growth_rate) {
      endPoint += `&from_growth_rate=${filters.growth_rate.values[0]}&to_growth_rate=${filters.growth_rate.values[1]}`;
    }
    return endPoint;
  };

  addCategoryAndSubcategoryToEndpoint = (endPoint: string) => {
    if (!!this.state.filterType) {
      const subCategoryList = this.state.categoriesList.filter(
        (categories) => categories.id === this.state.filterType
      );
      const subCategoriesList = subCategoryList.length
        ? subCategoryList[0].subCategories
        : [];
      if (subCategoriesList.some((sub) => sub.isChecked)) {
        endPoint += `&category_id=${this.state.filterType
          }&sub_category_ids=${subCategoriesList
            .filter((sub) => sub.isChecked)
            .map((sub) => sub.id)
            .join(",")}`;
      } else {
        endPoint += `&category_id=${this.state.filterType}`;
      }
    }
    return endPoint;
  };

  getCatalogueRequest = async() => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    let language = await getStorageData('language');
    if(!language){
      language = "en";
    }
    let endPoint = this.constructEndpoint();
    if(language!== "en"){
      endPoint = `${endPoint}&arabic_translate=true`
    }else{
      endPoint = `${endPoint}&arabic_translate=false`
    }
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatalogueAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${endPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCatogoryList = async(responseJson: {data: IResponseUser[]}) => {
    const catalogueListForThreeCard: ListingsCards[][] = [];
    let innerCatalogueList: ListingsCards[] = [];
    const language = await getStorageData("language");

    let catalogueList: CatalogueList[] = [];
    (language === "en") && (catalogueList = responseJson.data.map(
      (response: IResponseUser, index: number) => {
        let catlist = this.state.categoriesList;
        let attrs = response.attributes;
        let select = catlist.find((sele) => sele.id == attrs.category.id);
        let selectSubcat = select?.subCategories.find((subC) => subC.id == response.attributes.sub_category?.id);
        let titleImgSrc:string = "";
        (selectSubcat) && (selectSubcat.image) && (selectSubcat.image.url) && (titleImgSrc = selectSubcat.image.url);
        const catalogue = {
          id: response.id,
          title: response.attributes.name,
          titleImgSrc,
          type: response.attributes.category.attributes.name,
          basedIn: response.attributes.summarry.country,
          annualRevenue: response.attributes.annual_recurring_revenue,
          description: response.attributes.description,
          annualProfit: response.attributes.ltm_net_profit,
          foundedIn: response.attributes.date_founded,
          is_bookmarked: response.attributes.is_bookmarked,
          proposedValuation: response.attributes.proposed_valuation,
          originListing: response.attributes.preffered_language,
          employeesCount: response.attributes.number_of_employees,
          requestInitiated: response.attributes.deal_initiated,
          isDealClosed: response.attributes.closed,
          is_flag: response.attributes.is_flag,
        };
        innerCatalogueList.push({ ...catalogue });
        if ((index + 1) % 3 === 0) {
          catalogueListForThreeCard.push([...innerCatalogueList]);
          innerCatalogueList = [];
        }
        return catalogue;
      }
    ));

    let catalogueListAr: CatalogueList[] = [];

    (language !== "en") && (catalogueListAr = responseJson.data.map(
      (response: IResponseUser, index: number) => {
        let attrs = response.attributes;
        let catlist = this.state.categoriesList;
        let select = catlist.find((sele) => sele.id == attrs.category.id)
        let subselect = select?.subCategories.find((subC) => subC.id == response.attributes.sub_category?.id);
        let titleImgSrc:string = "";
        (subselect) && (subselect.image) && (subselect.image.url) && (titleImgSrc = subselect.image.url);
        const catalogue = {
          title: response.attributes.arabic_name,
          id: response.id,
          titleImgSrc,
          description: response.attributes.arabic_description,
          foundedIn: response.attributes.date_founded,
          basedIn: response.attributes.arabic_summary.country,
          employeesCount: response.attributes.number_of_employees,
          annualRevenue: response.attributes.annual_recurring_revenue,
          annualProfit: response.attributes.ltm_net_profit,
          is_bookmarked: response.attributes.is_bookmarked,
          requestInitiated: response.attributes.deal_initiated,
          proposedValuation: response.attributes.proposed_valuation,
          is_flag:response.attributes.is_flag,
          type: response.attributes.category.attributes.name,
          originListing: response.attributes.translated_preferred_language,
          isDealClosed: response.attributes.closed,
        };
        innerCatalogueList.push({ ...catalogue });
        if ((index + 1) % 3 === 0) {
          catalogueListForThreeCard.push([...innerCatalogueList]);
          innerCatalogueList = [];
        }
        return catalogue;
      }
    ));
    if (innerCatalogueList?.length !== 3) {
      catalogueListForThreeCard.push([...innerCatalogueList]);
      innerCatalogueList = [];
    }
    return ({catalogueList,catalogueListForThreeCard,catalogueListAr})
  }

  receiveCatalogueAPI = async(responseJson: Record<string, Object>) => {
    this.setState({loading : true})
    if (responseJson && (responseJson as {errors:{token:string}[]}).errors && (responseJson as {errors:{token:string}[]}).errors[0].token) {
      toast.error((responseJson as {errors:{token:string}[]}).errors[0].token);
      this.navigateToSignUp();
    } else if (responseJson && responseJson.message) {
      this.setState({ listingsCards: [], catalogueListForThreeCard: [] });
    } else if (responseJson && responseJson.data) {
      const { catalogueList, catalogueListAr, catalogueListForThreeCard } = await this.getCatogoryList(responseJson as unknown as {data: IResponseUser[]})
      this.handlelanguage(catalogueList, catalogueListForThreeCard, catalogueListAr);    
    }
  };

  

  handlelanguage = async(catalogueList: CatalogueList[], catalogueListForThreeCard: ListingsCards[][], catalogueListAr: CatalogueList[]) => {
    const language = await getStorageData("language");
    let { listingsCards } = this.state;
    listingsCards = [];
    if(language === "en"){
      catalogueList.forEach((catalogue) => {
        if (!catalogue.is_flag) {
          listingsCards.push(catalogue);
        }
      });
      this.setState({
        listingsCards: listingsCards,
        catalogueListForThreeCard,
        loading:false
      });

    }else{
      catalogueListAr.forEach((catalogue) => {
        if (!catalogue.is_flag) {
          listingsCards.push(catalogue);
        }
      });
      this.setState({
        listingsCards: listingsCards,
        catalogueListForThreeCard,
        loading:false
      });
    }
  }

  getCategoriesRequest = async () => {
    await getStorageData("language");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData('token'),
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.getCategoriesAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  receiveCategoriesAPI = async(responseJson: any) => {
    const lang = await getStorageData('language') || "en";
    if (responseJson && responseJson.errors && responseJson.errors[0].token) {
      toast.error(responseJson.errors[0].token);
      this.navigateToSignUp();
    } else if (responseJson && responseJson.data) {
      const categoriesListing = responseJson.data.map((response: any) => {
        const categories = {
          id: response.attributes.id.toString(),
          name: response.attributes.name,
          subCategories: response.attributes.sub_categories.map(
            (subCategory: any) => ({
              id: subCategory.id.toString(),
              name: lang === 'ar' ? subCategory.arabic_sub_category : subCategory.name,
              image: subCategory.image,
              isChecked: false,
            })
          ),
        };
        return categories;
      });
      this.setState({ categoriesList: categoriesListing });
      
      let tempBusinessCat = categoriesListing.find((category: any) => category.name === 'Start Up');
      let tempBusinessCatAr = categoriesListing.find((category: any) => category.name === 'شركة ناشئة');
      this.setState({categoryIdNew: tempBusinessCat?.id});
    }
  };
  
  redirectToUrl = (id: string, categoryId: string) => {
    const categoryURl = categoryId ===this.state.categoryIdNew ? `/StartupDetails/${id}` : `/CompanyProfileEdit/${id}`
    this.props.navigation.history.push(categoryURl);
  }

  summaryButtonDisable = () => {
    const {
      dateFounded,
      startupTeamSize,
      startupTypeCategory,
      country,
      city,
      headline,
      description,
      askingPrice,
      askingPriceReasoning,
    } = this.state;
    return (
      !dateFounded ||
      !startupTeamSize ||
      !startupTypeCategory ||
      !country ||
      !city ||
      !headline ||
      !description ||
      !askingPrice ||
      !askingPriceReasoning
    );
  };
  validatePrivateForm = () => {
    const { startupName, startupWebsite, competitors, plStatement} = this.state;
    const privateInfoError = {
      startupName: this.validateFieldPrivateInfo('startupName', startupName),
    startupWebsite: this.validateFieldPrivateInfo('startupWebsite', startupWebsite),
    competitors: this.validateFieldPrivateInfo('competitors', competitors),
    plStatement: this.validateFieldPrivateInfo('plStatement', plStatement),
    };
    this.setState({ privateInfoError });
    return Object.values(privateInfoError).every(error => error);
  };
  validateFieldPrivateInfo =  (field: string, value: any): boolean => {
    switch (field) {
      case 'startupName':
        return /^[a-zA-Z0-9\s]+$/.test(value.trim());
      case 'startupWebsite':
        const websiteRegex = /^(https?:\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
        return websiteRegex.test(value)
      case 'competitors':
        return value!=="" && value.length > 0 && value.every((comp: string) => comp.trim() !== '');
      case 'plStatement':
        return value !== null;
      default:
        return true;
    }
  };
  getErrorMessagePrivateInfo = (field: string | boolean, value: any): string => {
    switch (field) {
      case 'startupName':
        return this.getErrorForSpecialChar("Startup Name",value)
      case 'startupWebsite':
        return this.getErrorForwebsite("Website",value);
      case 'competitors':
        return this.getErrorForCompetitors(value);
      case 'plStatement':
        return value !== null && value !== ""? '':"P&L statement is required.";
      default:
        return '';
    }
  };
  onPrivateInformationSave = async () => {
   
  if (this.validatePrivateForm()) {
      this.setState({privateInfoFlag:false})
      this.privateInformationSave();
  }else{
    this.setState({
      privateInfoFlag:true
   } )
  }
  };
  privateInformationSave=async()=>{
    const header = {
      token: await getStorageData('token'),
    };

  const formDataInfo = new FormData();

  formDataInfo.append("private_information[startup_name]",this.state.startupName);
  formDataInfo.append("private_information[startup_website]",this.state.startupWebsite);
  this.state.competitors.forEach((fileObj, index) => {
  formDataInfo.append(`private_information[competitors[]`, fileObj);
    });
    formDataInfo.append(
      "private_information[p_and_l_statements][]",
      this.state.plStatement
    );
    this.state.arrayFiles.forEach((fileObj, index) => {
      formDataInfo.append(
      `private_information[document_titles_attributes[${index}][document]`,
      fileObj.uploadedFile
    );
    formDataInfo.append(
      `private_information[document_titles_attributes[${index}][title]`,
      fileObj.title
    );
  });
    formDataInfo.append(
      "private_information[auto_sign_nda]",
      `${this.state.autoSignNDA}`
    );

  formDataInfo.append("private_information[auto_accept_request]", `${this.state.autoAcceptRequest}`);
  formDataInfo.append("listing_id", `${this.state.listId}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePrivateInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/start_ups/update_private_informations`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataInfo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  privateInformationDisable = () => {
    return (
      !this.state.startupName ||
      !this.state.startupWebsite ||
      !this.state.competitors ||
      !this.state.plStatement
    );
  };

  privateInformationClear = () => {
    const initialState = {

      startupName: "",
      startupWebsite: "",
      competitors: ["", "", ""],
      plStatement: null,
      arrayFiles:[],
      autoSignNDA:false,
      autoAcceptRequest:false,

    };
    this.setState(initialState);
  };
 
  validateFieldSummary =  (field: string, value: any): boolean => {
    switch (field) {
      case 'dateFounded':
        return value.trim() !== '';
      case 'startupTeamSize':
        return  /^\d+$/.test(value);
      case 'otherCatText':
        return   conditionSort(this.state.startupTypeCategory == this.state.otherSubCatId, /^\d+$/.test(value), true);
      case 'startupTypeCategory':
        return value.trim() !== ''
      case 'country':
        return value.trim() !== '';
      case 'city':
        return value.trim() !== '';
      case 'headline':
        return  value.trim() !== '';
      case 'description':
        return  value.trim() !== ''
      case 'askingPrice':
        return /^\d+(\.\d{1,2})?$/.test(value);
      case 'askingPriceReasoning':
        return value.trim() !== ''
      default:
        return true;
    }
  };
  getErrorMessage = (field: string | boolean, value: any): string => {
    switch (field) {
      case 'dateFounded':
        return value===""?"Date is required":"";
      case 'startupTeamSize':
        return this.getErrorForTeamSize(value);
      case 'startupTypeCategory':
        return this.getErrorForSpecialChar("Startup type category",value);
      case 'country':
        return this.getErrorForSpecialChar("Country",value);
      case 'city':
        return this.getErrorForSpecialChar("City",value)
      case 'headline':
        return value===""?"Headline is required":"";
      case 'description':
        return value===""?"Description is required":"";
      case 'askingPrice':
        return this.validateErrorMsgNumber("Asking Price",value)
      case 'askingPriceReasoning':
        return value===""?"Asking Price Reasoning is required":"";
      case 'otherCatText':
        return conditionSort(this.state.startupTypeCategory == this.state.otherSubCatId && this.state.otherCatText == '', 'Other category name is requried', "");
      default:
        return '';
    }
  };
  
  getErrorForSpecificChar=(fieldName: string | boolean, value: any) => {
    const specialharRegex = /^[a-zA-Z0-9.,\s]+$/
    if (value === "") {
      return `${fieldName} is required`;
    } else if (!specialharRegex.test(value)) {
      return `No special characters allowed in ${fieldName}`;
    }
    return '';
  };
 getErrorForSpecialChar=(fieldName: string | boolean, value: any) => {
  const specialharRegex = /^[a-zA-Z0-9\s]+$/
  if (value === "") {
    return `${fieldName} is required`;
  } else if (!specialharRegex.test(value)) {
    return `No special characters allowed in ${fieldName}`;
  }
  return '';
};
getErrorForwebsite=(fieldName: string | boolean, value: any) => {
  const websiteRegex = /^(https?:\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
  if (value === "") {
    return `${fieldName} is required`;
  } else if (!websiteRegex.test(value)) {
    return `Invalid website URL`;
  }
  return '';
};

  getErrorForCompetitors=(value:any)=>{
    if (value!=="" && value.length > 0 && value.every((comp: string) => comp.trim() !== '')) {
      return '';
    } else {
      return 'All Competitors name is required';
    }
        
  }
  getErrorForTeamSize=(value:any)=>{
    const numberrgx=/^\d+$/.test(value)
    if (value === "") {
      return 'Start up Team Size is required'
    } else if (! numberrgx) {
      return  'Start up Team size must be a number.';
    }
    return '';

  }
  validateForm = () => {
    const { dateFounded,startupTeamSize, startupTypeCategory,country,city,headline,description,askingPrice,askingPriceReasoning} = this.state;
    const summaryError = {
    dateFounded: this.validateFieldSummary('dateFounded', dateFounded),
    startupTeamSize: this.validateFieldSummary('startupTeamSize', startupTeamSize),
    startupTypeCategory: this.validateFieldSummary('startupTypeCategory', startupTypeCategory.toString()),
    country: this.validateFieldSummary('country', country),
    city: this.validateFieldSummary('city', city),
    headline: this.validateFieldSummary('headline', headline),
    description: this.validateFieldSummary('description', description),
    askingPrice: this.validateFieldSummary('askingPrice', askingPrice),
    askingPriceReasoning: this.validateFieldSummary('askingPriceReasoning', askingPriceReasoning),
    otherCatText: conditionSort(startupTypeCategory == this.state.otherSubCatId, !!this.state.otherCatText, true)
    };
    this.setState({ summaryError });
    return Object.values(summaryError).every(error => error);
  };


  summaryApiCall = async () => {
    const requestBody = {
      summarry: {
        date_founded: this.state.dateFounded,
        startup_team_size: this.state.startupTeamSize,
        country: this.state.country,
        city: this.state.city,
        description: this.state.description,
        asking_price: this.state.askingPrice,
        asking_price_reasoning: this.state.askingPriceReasoning,
        sub_category_id: this.state.startupTypeCategory,
        other_category: conditionSort(this.state.startupTypeCategory == this.state.otherSubCatId, this.state.otherCatText, undefined),
        headline: this.state.headline,
      },
      listing_id: this.state.listId,
    };
const token = await getStorageData("token");
const header = {
  "Content-Type": configJSON.productApiContentType,
  token: token,
  locale: this.state.language
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.updateSummaryDetailsId = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.summaryOverViewEndpoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.apiMethodTypePut
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(requestBody)
);
runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSummarySave = async () => {
    if (this.validateForm()) {
      this.setState({ summaryFlag: false });
      if (this.state.startupTypeCategory == this.state.otherSubCatId && this.state.otherCatText) { 
        this.onNewCategorySave(); 
        return;
      }
      this.summaryApiCall();

    } else {
      this.setState({
        summaryFlag: true
      })
    }
  };
  onSummaryClear = () => {
    const initialState = {
      dateFounded: "",
      startupTeamSize: "",
      startupTypeCategory: "",
      country: "",
      city: "",
      headline: "",
      description: "",
      askingPrice: "",
      askingPriceReasoning: "",
    };
    this.setState(initialState);
  };

  companyButtonDisable = () => {
    return (
      !this.state.businessModelAndPricing ||
      !(
        conditionSort(this.state.growthOpputunityOther, conditionSort(this.state.growOppurtunityOtherValue.length > 0, true, false), this.state.growthOpportunity.length > 0)
      ) ||
      !(conditionSort(this.state.keyAssestsOther, conditionSort(this.state.keyAssestsOtherValue.length > 0, true, false), this.state.keyAssets.length > 0))
    );
  };
  getErrorMessageCompany  = (field: string | boolean, value: any): string => {
    switch (field) {
      
      case 'businessModelAndPricing':
      return this.getErrorForSpecificChar("Business Model And Pricing",value)
      case 'techStack':
        return this.getErrorForSpecificChar("Tech Stack",value)
        case 'growthOpportunity':
          return (
            !this.state.growthOpportunity.length && !this.state.growthOpputunityOther
          ) ?"Growth Opportunity is required":"";
        case 'keyAssets':
          return !this.state.keyAssets.length ? "Key Assets is required":"";
      default:
        return '';
    }
  };

  validateFieldCompany  =  (field: string, value: any): boolean => {
    const regex = /^[a-zA-Z0-9\W]+$/
    switch (field) {
      case 'businessModelAndPricing':
        return regex.test(value.trim());
      case 'techStack':
        return regex.test(value.trim());
        case 'growthOpportunity':
          return conditionSort(this.state.growthOpputunityOther, conditionSort(this.state.growOppurtunityOtherValue.length > 0, true, false), value.length > 0);
        case 'keyAssets':
          return conditionSort(this.state.keyAssestsOther, conditionSort(this.state.keyAssestsOtherValue.length > 0, true, false), value.length > 0) ;
      default:
        return true;
    }
  };
  validateFormCompany = () => {
    const { businessModelAndPricing,techStack,keyAssets,growthOpportunity} = this.state;
    const companyError = {
      businessModelAndPricing: this.validateFieldCompany('businessModelAndPricing', businessModelAndPricing),
      techStack:this.validateFieldCompany('techStack', techStack),
      growthOpportunity: this.validateFieldCompany('growthOpportunity', growthOpportunity),
      keyAssets:this.validateFieldCompany('keyAssets', keyAssets),
    };
    this.setState({ companyError});
    return Object.values(companyError).every(error => error);
  };
  onCompanyOverViewSave = async () => {
    if (this.validateFormCompany()) {
      this.setState({companyFlag:false})
      const token = await getStorageData("token");
      let growthObj: any = this.state.growthOpportunity.reduce(
        (acc: { [key: string]: boolean }, curr: string) => {
          acc[curr] = true;
          return acc;
        },
        {}
      );
      growthObj = {
        ...growthObj,
        ...(this.state.growthOpputunityOther
          ? { other: this.state.growOppurtunityOtherValue }
          : {}),
      };
      let keyObj: any = this.state.keyAssets.reduce(
        (acc: { [key: string]: boolean }, curr: string) => {
          acc[curr] = true;
          return acc;
        },
        {}
      );
      keyObj = {
        ...keyObj,
        ...(this.state.keyAssestsOther
          ? { other: this.state.keyAssestsOtherValue }
          : {}),
      };
      const requestBody = {
        listing_id: this.state.listId,
        company_overview: {
          question1: "What is your business model and pricing?",
          answer1: this.state.businessModelAndPricing,
          question2: "What tech stack is this product or service built on?",
          answer2: this.state.techStack,
          list_of_product: this.state.products,
        },
        company_growth_opportunity: growthObj,
        company_key_asset: keyObj,
      };
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: token,
        locale: this.state.language
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateComapnyId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.companyOverviewEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePut
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }else{
      this.setState({companyFlag:true})
    }
  };
  onCompanyOverViewClear = () => {
    this.setState({
      keyAssestsOther: false,
      keyAssets: [],
      keyAssestsOtherValue: "",
      businessModelAndPricing: "",
      techStack:"",
      growOppurtunityOtherValue: "",
      growthOpportunity: [],
      growthOpputunityOther: false,
      products: ["", "", ""]
    });
  };

  onFileUpload = () => {
    if (this.state.modalFile && this.state.modalFileTitle) {
      this.setState({
        arrayFiles: [
          ...this.state.arrayFiles,
          {
            uploadedFile: this.state.modalFile,
            title: this.state.modalFileTitle,
          },
        ],
      });
      this.setState({
        modalFile: null,
        modalFileTitle: "",
        openFileModal: false,
      });
    }
  };

  
  validateFieldMetricsData  =  (field: string | boolean, value: any): boolean => {
    if(field==='numberOfUsers'){
      return  /^\d+(\.\d{1,7})?$/.test(value)
    }
        return true;
    
  };
  getErrorMessageMetrics  = (field: string | boolean, value: any): string => {
    if (field === 'numberOfUsers') {
      return this.validateErrorMsgNumber("Number of users", value);
    } else {
      return '';
    }
  };
  
  validateFormMetrics = () => {
    const {numberOfUsers} = this.state;
    const metricError = {
      numberOfUsers: this.validateFieldMetricsData ('numberOfUsers', numberOfUsers)
    };
    this.setState({metricError });
    return Object.values(metricError).every(error => error)
  };


  onMetricSave = async () => {
  
    if (this.validateFormMetrics()) {
      this.setState({
        matricsFlag:false
      })
      const token = await getStorageData("token");
      const requestBody = {
        customer_metric: {
          number_of_users: this.state.numberOfUsers,
        },
        listing_id: this.state.listId,
      };
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: token,
        locale: this.state.language
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateMetricInformationId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.startupMetricUpdateEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePut
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    else{
this.setState({
  matricsFlag:true
})
    }
  };


  onNewCategorySave = async () => {
  
      const token = await getStorageData("token");
      const requestBody = {
        "sub_category_name": this.state.otherCatText,
        "category_id": this.state.categoryIdNew
    }
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: token,
        locale: this.state.language
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.postNewCategoryAPIId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postCategoryEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onCancelMetric = () => {
    this.setState({ numberOfUsers: "" });
  };

  extractCategoryID = (categoryName: string) => {
    
    const category = this.state.categoriesList.find(item => item.name === categoryName);
    return category ? category.id : null;
  }

  onFinancialButtonDisable = () => {
    return (
      !this.state.ltmgross ||
      !this.state.ltmnet ||
      !this.state.annualRecurringRevenue ||
      !this.state.annualGrowthRate ||
      !this.state.lastMonthGrossRevenue ||
      !this.state.lastMonthNetProfit
    );
  };



  validateFieldFinance  =  (field: string, value: any): boolean => {
    const fieldsToValidate = [
      'startupTeamSize',
      'grossRevenue',
      'netProfit',
      'annualRevenue',
      'annualGrowthRate',
      'grassRevenue',
      'lastMonthNetProfit',
    ];
    if (fieldsToValidate.includes(field)) {
      return /^\d+(\.\d{1,7})?$/.test(value);
    }
  
    return true;
    
  };
  validateErrorMsgNumber = (fieldName: string, value: any) => {
    const numberRegex = /^\d+(\.\d{1,7})?$/;
    if (value === "") {
      return `${fieldName} is required`;
    } else if (!numberRegex.test(value)) {
      return `${fieldName} must be a number`;
    }
    return '';
  };
  getErrorMessageFinance  = (field: string | boolean, value: any): string => {
    switch (field) {
      
      case 'grossRevenue':
      return  this.validateErrorMsgNumber("LTM Gross Revenue ", value);

        case 'netProfit':
          return  this.validateErrorMsgNumber("LTM Net Profit ", value);
         
        case 'annualRevenue':
          return  this.validateErrorMsgNumber("Annual Recurring Revenue ", value);
      
        case 'annualGrowthRate':
          return  this.validateErrorMsgNumber("Annual Growth Rate ", value);

        case 'grassRevenue':
          return  this.validateErrorMsgNumber("Last Month Gross Revenue", value);

        case 'lastMonthNetProfit':
          return  this.validateErrorMsgNumber("Last Month Net Profit", value);

      default:
        return '';
    }
  };
  
  validateFormFinance = () => {
    const {ltmgross,ltmnet,annualRecurringRevenue,annualGrowthRate,lastMonthGrossRevenue,lastMonthNetProfit,multipleNumber} = this.state;
    const financialError = {
      grossRevenue:this.validateFieldFinance ('grossRevenue', ltmgross),
        netProfit:this.validateFieldFinance ('netProfit', ltmnet),
        annualRevenue:this.validateFieldFinance ('annualRevenue', annualRecurringRevenue),
        annualGrowthRate:this.validateFieldFinance ('annualGrowthRate', annualGrowthRate),
        grassRevenue:this.validateFieldFinance ('lastMonthGrossRevenue', lastMonthGrossRevenue),
        lastMonthNetProfit:this.validateFieldFinance ('lastMonthNetProfit', lastMonthNetProfit)
    };
    this.setState({financialError });
    return Object.values(financialError).every(error => error);
  };
  onFinancialSave = async () => {

    if (this.validateFormFinance()) {
      this.setState({
        financeFlag:false
      })
      const token = await getStorageData("token");
      const formData = new FormData();

      formData.append("listing_id", `${this.state.listId}`);
      formData.append("financial[ltm_gross_revenue]", this.state.ltmgross);
      formData.append("financial[ltm_net_profit]", this.state.ltmnet);
      formData.append(
        "financial[annual_recurring_revenue]",
        this.state.annualRecurringRevenue
      );
      formData.append(
        "financial[annual_growth_rate]",
        this.state.annualGrowthRate
      );
      formData.append(
        "financial[last_month_gross_revenue]",
        this.state.lastMonthGrossRevenue
      );
      formData.append(
        "financial[last_month_net_profit]",
        this.state.lastMonthNetProfit
      );
      formData.append(
        "financial[multiple_type]",
        this.state.multipleType === "ARR Multiple"
          ? "arr_multiple"
          : "revenue_multiple"
      );
      formData.append(
        "financial[multiple_number]",
        `${this.state.multipleNumber}`
      );

      const header = {
        // "Content-Type": configJSON.productApiContentType,
        token: token,
        locale: this.state.language
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateFinancialInformationId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.FinancialUpdateEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePut
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }else{
      this.setState({
        financeFlag:true
      })
    }
  };
  onFinancialClear = () => {
    const initialState = {
      ltmgross: "",
      ltmnet: "",
      annualRecurringRevenue: "",
      annualGrowthRate: "",
      lastMonthGrossRevenue: "",
      lastMonthNetProfit: "",
      multipleType: "",
      multipleNumber: "",
    };
    this.setState(initialState);
  };

  onAqcuisationButtonDisable = () => {
    return (
        !this.state.reasonForSelling ||
        !(
          this.state.financialSources.length > 0 && conditionSort(this.state.financialSoucesOther, conditionSort(this.state.financialSoucesValue.length > 0, true, false), true)
        ) 
    );
  };
  onAcquisitionDetailsSave = async () => {
    if (!this.onAqcuisationButtonDisable()) {
      this.setState({acquisitionError: false})
      let acquisition_detail: any = this.state.financialSources.reduce(
        (acc: { [key: string]: boolean }, curr: string) => {
          acc[curr] = true;
          return acc;
        },
        {}
      );
      acquisition_detail = {
        ...acquisition_detail,
        reason_for_selling: this.state.reasonForSelling,
        ...(this.state.financialSoucesOther
          ? { other: this.state.financialSoucesValue }
          : {}),
      };
      const token = await getStorageData("token");
      const requestBody = {
        listing_id: this.state.listId,
        acquisition_detail,
      };
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: token,
        locale: this.state.language
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateAcquisitionDetailsId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.acquisationUpdateEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePut
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({acquisitionError: true});
    }
  };

  onAqcuisitionDetailsClear = () => {
    this.setState({
      reasonForSelling: "",
      financialSources: [],
      financialSoucesOther: false,
      financialSoucesValue: "",
    });
  };

  handlePopver = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  getListofItems = async () => {
    this.getCategoriesRequest();
    await getStorageData("language");
    this.getLang();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListApiEndPoint}?locale=${this.state.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getListings = async () => {
    await getStorageData("language");
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/listings/get_list?listing_id=${this.state.listId}&locale=${this.state.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createListing = async () => {
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.productApiContentType,
      locale: this.state.language
    };

    let endPoint = `${configJSON.createListingEndpoint}`;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createListsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let categoryid = this.state.categoryIdNew;

    if(this.state.openBuisnessBox) {
      let temp: any = this.state.categoriesList.find((category: any) => category.name === 'SME');
      categoryid = temp.id;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({category_id: categoryid})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePecentage = async (percentageOfProgress: string) => {
    if (percentageOfProgress === "100") {
      this.setState({ AddListingButton: true });
    }
    this.setState({ progress: percentageOfProgress });
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.productApiContentType,
      locale: this.state.language
    };
    const httpBody = {
      listing_id: this.state.listId,
      percent: percentageOfProgress,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateListsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_catalogue/listings/update_list"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCompanyOverViews = async () => {
    await getStorageData("language")
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompayOverViewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyOverViewEndPoint}?listing_id=${this.state.listId}&locale=${this.state.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPrivateInfo = async () => {
    await getStorageData("language");
    const token = await getStorageData("token");
    const header = {
      // "Content-Type": configJSON.productApiContentType,
      token: token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivateInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPrivateInformation}?listing_id=${this.state.listId}&locale=${this.state.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSummaryValue=(value:any)=>{
return value ?? ""

  }

  getPLStatement=(plData:any)=>{
    return plData.length > 0 ? plData : null;
  }
  getCompatitorInitial=(competitr:any)=>{
    return competitr.length >0 ? competitr: ["", "", ""];
  }
  getAllVariable=(responseJson:any,financialArray:string[])=>{
    const { data } = responseJson;
    const updatedState = {
      startupName: this.getSummaryValue(data.attributes.private_information.data.attributes.startup_name),
      listingStatus: data.attributes.listing_status ?? "",
      startupWebsite: this.getSummaryValue(data.attributes.private_information.data.attributes.startup_website),
      competitors:this.getCompatitorInitial(data.attributes.private_information.data.attributes.competitors),
      plStatement : this.getPLStatement(data.attributes.private_information.data.attributes.p_and_l_statements),
      autoSignNDA: data.attributes.private_information.data.attributes.auto_sign_nda,
      autoAcceptRequest:
        data.attributes.private_information.data.attributes.auto_accept_request,
      dateFounded: this.getSummaryValue(data.attributes.summarry.data.attributes.date_founded),
      startupTeamSize: this.getSummaryValue(data.attributes.summarry.data.attributes.startup_team_size),
      startupTypeCategory: data.attributes.summarry.data.attributes.sub_category_id ?? "",
      country:data.attributes.summarry.data.attributes.country??"",
      city: data.attributes.summarry.data.attributes.city??"",
      headline: data.attributes.summarry.data.attributes.headline ??"",
      description: data.attributes.summarry.data.attributes.description ??"",
      askingPrice: data.attributes.summarry.data.attributes.asking_price??"",
      askingPriceReasoning: this.getSummaryValue(data.attributes.summarry.data.attributes.asking_price_reasoning),
      businessModelAndPricing: this.getSummaryValue(data.attributes.company_overview.company_overview.answer1 ),
      techStack: this.getSummaryValue(data.attributes.company_overview.company_overview.answer2),
      financialSources: financialArray,
      ltmgross: this.getSummaryValue(data.attributes.financial.data.attributes.ltm_gross_revenue),
      ltmnet: this.getSummaryValue(data.attributes.financial.data.attributes.ltm_net_profit),
      annualRecurringRevenue:
        data.attributes.financial.data.attributes.annual_recurring_revenue ?? "",
      annualGrowthRate: this.getSummaryValue(data.attributes.financial.data.attributes.annual_growth_rate ),
      lastMonthGrossRevenue: this.getSummaryValue(data.attributes.financial.data.attributes.last_month_gross_revenue ),
      lastMonthNetProfit: this.getSummaryValue(data.attributes.financial.data.attributes.last_month_net_profit),
      multipleType:
        data.attributes.financial.data.attributes.multiple_type === this.state.startUpPageText.arrStringValue
          ? this.state.startUpPageText.arrMultipleTypeHeading
          : this.state.startUpPageText.revenueMultipleText,
      multipleNumber: data.attributes.financial.data.attributes.multiple_number ??"",
      numberOfUsers:data.attributes.customer_metric.data.attributes.number_of_users ??"",
      financialSoucesOther:
        data.attributes.acquisition_detail.data.attributes.other !== null &&
        data.attributes.acquisition_detail.other !== "",
      financialSoucesValue:
        data.attributes.acquisition_detail.data.attributes !== null &&
        data.attributes.acquisition_detail.data.attributes.other,
      progress: data.attributes.progress,
    };
    return updatedState
  }
  getListResponse = (responseJson: any) => {
    const { data } = responseJson;
    let financialArray: string[] = [];
    if (data.attributes?.acquisition_detail.data !== null) {
      const financialData = data.attributes.acquisition_detail.data.attributes
      this.setState({
        reasonForSelling: data.attributes.acquisition_detail.data.attributes.reason_for_selling ?? "",
      });
      financialArray = this.extractTrueValues(financialData);
    }
      if(data.attributes.private_information.data.attributes.competitors.length > 0){
        this.setState({  
          competitors: data.attributes.private_information.data.attributes.competitors
        });
      }
    const updateData= this.getAllVariable(responseJson,financialArray)
    this.setState(updateData);
  };
  fetchFile = async (url:string,filename:string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = filename; // You can derive this from the backend response if needed
    return new File([blob], fileName, { type: blob.type });
  };

  getPrivateInformationFromApi = (responseJson: any) => {
    if (responseJson.data) {
      const { data } = responseJson;
      if(data?.attributes?.p_and_l_statements !== "[]"){
      const pandlStatement = data?.attributes?.p_and_l_statements[0];
      if (pandlStatement) {
        
        this.fetchFile(data.attributes?.p_and_l_statements[0].file_path,data.attributes?.p_and_l_statements[0].file_name).then(file => {
          this.setState({ plStatement: file });
        });

      }
    }
      if (data?.attributes?.documents != "[]" && data?.attributes?.documents?.length > 0) {
        const documents: any[] = data.attributes?.documents;
        const arrList: { title: string; uploadedFile: any }[] = [];
        documents.forEach((element, index) => {
         
          this.fetchFile(element.file_path,element.document_title).then(file => {
            arrList.push({
              title: element.document_title?element.document_title:"",
              uploadedFile: file
            });
          });
        
          this.setState({ arrayFiles: arrList });
        });
      }
    }
  };

  extractTrueValues = (data: any) => {
    const trueValues = [];
    if (data !== null) {
      for (const key in data) {
        if (data[key] === true) {
          trueValues.push(key);
        }
      }
    }
    return trueValues;
  };

  checkCompanyAssests = (data: any) => {
    let keyAssetsList: string[] = [];
    if (data && data.company_key_asset?.data) {
      keyAssetsList = this.extractTrueValues(data.company_key_asset.data.attributes);
      this.setState({
        keyAssets: keyAssetsList,
        keyAssestsOther: data.company_key_asset.data.attributes.other !== null ? true : false,
        keyAssestsOtherValue: data.company_key_asset.data.attributes.other || "",
      });
    }
  }
  productsCheck = (data: any) => {
    if (
      data &&
      data.company_overview &&
      data.company_overview.list_of_product &&
      data.company_overview.list_of_product.length > 0
    ) {
      this.setState({ products: data.company_overview.list_of_product });
    }
  }
  companyOverView = (responseJson: any) => {
    const data = responseJson;

    let growthOppList: string[] = [];
    this.checkCompanyAssests(data);
    if (data && data.growth_opportunity?.data) {
      growthOppList = this.extractTrueValues(data.growth_opportunity.data.attributes);
      this.setState({
        growthOpportunity: growthOppList,
        growthOpputunityOther: data.growth_opportunity.data.attributes.other !== null ? true : false,
        growOppurtunityOtherValue: data.growth_opportunity.data.attributes.other || "",
      });
    }
    this.productsCheck(data);
    
  };
  handleResForCreateListing = (from: string, message: Message) => {
    if (
      this.createListsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.setState({ isListingModalSuccess: true });
      }
      if (responseJson.errors) {
        this.setState({successStartUpModal: false, isListingModal: false }, () => toast.error(responseJson.errors[0]));
        return;
      }
      this.getListofItems();
    }
  };

  handleResAddSubCat = (from: string, message: Message) => {
    if (
      this.postNewCategoryAPIId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if(responseJson.data && responseJson.data.length) {
        const temp = [...responseJson.data];
        let found = temp.find((object) => object.attributes.name == this.state.otherCatText);
        this.setState({
          otherCatText: "",
          startupTypeCategory: found.id
        }, () => {
          this.summaryApiCall();
          this.getCategoriesRequest();
        })
      }
     
    }
  };
  handleResForUpdatingPercentage = (from: string, message: Message) => {
    if (
      this.updateListsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.setState({ isListingModalSuccess: true });
      }
      if (responseJson.errors) {
        this.setState({successStartUpModal: false, isListingModal: false }, () => toast.error(responseJson.errors[0]));
        return;
      }
      if (responseJson.data.attributes.progress == "100.0") {
        this.setState({ isListingModal: true, successStartUpModal: true,hashId: responseJson.data.attributes.hash_id	 });
      }
    }
  };

  handleStartUpBox = () => {
    const content =this.state.language === 'ar' ?  configJSON.startUpContent_ar : configJSON.startUpContent
    const title =this.state.language === 'ar' ?  configJSON.startUpText_ar : configJSON.startUpText
    this.setState({ openStartUpBox: true });
    this.setState({ openBuisnessBox: false });
    this.setState({ boxTitle: title });
    this.setState({ boxContent: content });
  };

  handleBuisnessBox = () => {
    const content = this.state.language === 'ar' ? configJSON.businessContent_ar :configJSON.businessContent;
    const title = this.state.language == 'ar' ? configJSON.businessText_ar  :configJSON.businessText;
    this.setState({ openStartUpBox: false });
    this.setState({ openBuisnessBox: true });
    this.setState({ boxTitle: title });
    this.setState({ boxContent: content });
  };

  handleListingModal = (stats: boolean) => {
    this.setState({ isListingModal: stats, isListingModalSuccess: false });
    const dataNewList:{
      id: string;
      type: string;
      attributes: {
          id: number;
          name: string,
          progress: string;
          category_id: number;
          account_id: number;
          category_name:string;
      }
  }= this.state.list.reduce((max:any, item):any => item.id > max.id ? item : max, this.state.list[0]);
  if (dataNewList?.attributes.category_name === this.state.startUpPageText.smeText || dataNewList?.attributes.category_name ==="نحن") {
    this.props.navigation.history.push(
      `/CompanyProfileEdit/${dataNewList?.attributes.id}`
    );
  } else {
    this.props.navigation.history.push(
      `/startupDetails/${dataNewList?.attributes.id}`
    );
  }
  };

  handleResForUpdateApi = (callID: string, from: string, message: Message) => {
    if (
      callID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.error) {
        toast.error(responseJson.error,
          {
            position : this.checkIfCondtionForToast( toast.POSITION.TOP_LEFT , toast.POSITION.TOP_RIGHT),
            style : {
              direction :  this.checkIfConditionForDirection()
            }
          
      });
      } else {
        this.updateProgress();
        toast.success(this.state.startUpPageText.successMessageToastText,
        {
          position : this.checkIfCondtionForToast( toast.POSITION.TOP_LEFT , toast.POSITION.TOP_RIGHT),
          style : {
            direction :  this.checkIfConditionForDirection()
          }});
      }
      this.setState({
        expand: ''
      })
    }
  };

  applyTranslationOfCatalogue = async(lang:string) => {
     this.setState({cataloguePageText: this.state.cataloguePageText});
     if(lang ==="ar"){
      this.setState({filterRange :[
        { values: `10 - 100`, isChecked: false },
        { values: `100 - 1000`, isChecked: false },
        { values: `1000 - 10000`, isChecked: false },
        { values: `10000 - 100000`, isChecked: false },
        { values: `100000 - 1000000`, isChecked: false },
        { values: `اكثر من 1000000`, isChecked: false },
      ]});
     }
     const sortedData =[
      { id: "price_low_to_high", name: "السعر: من الأدنى إلى الأعلى"},
      { id: "price_high_to_low", name: "السعر: من الأعلى إلى الأدنى" },
      { id: "a_to_z", name: "من الألف إلى الياء" },
      { id: "date_oldest", name: "تاريخ الإدراج: الأقدم" },
      { id: "date_newest", name: "تاريخ الإدراج: الأحدث" },
    ]
    this.setState({sortByData : lang ==="ar" ? sortedData : this.sortByFilterList})
     
  }
  checkIfCondtionForToast = (valueForTrue: ToastPosition, valueForFalse: ToastPosition) =>{
    if(this.state.language === "ar"){
      return valueForTrue
    }else{
      return valueForFalse
    }
  }

  checkIfConditionForDirection = () =>{
    if(this.state.language ==="ar"){
      return "rtl"
    }else{
     return "ltr"
    }
  }

  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }


  updateProgress = () => {
    let progress = 0;
    if (this.state.startupName) {
      progress += 16.66;
    }
    if (this.state.dateFounded) {
      progress += 16.66;
    }
    if (this.state.techStack) {
      progress += 16.66;
    }
    if (this.state.ltmgross) {
      progress += 16.66;
    }
    if (this.state.numberOfUsers) {
      progress += 16.66;
    }
    if (this.state.reasonForSelling) {
      progress += 16.7;
    }
    if (`${progress}` !== this.state.progress) {
      this.updatePecentage(`${progress}`);
    }
  };

  caniAddNewListing = (responseJson: any) => {
    const { data } = responseJson;
    for (const element of data) {
      if (element.attributes.progress !== "100.0") {
        return false;
      }
    }
    return true;
  };
  updateProgressOnEachTime = (responseJson: any) => {
    if(responseJson.data){
    this.setState({ list: responseJson.data });
    this.setState({ AddListingButton: this.caniAddNewListing(responseJson) });
    if (!this.state.listId) {
      if (responseJson.data[0].attributes.category_name === this.state.startUpPageText.smeText ||responseJson.data[0].attributes.category_name==="نحن" ) {
        this.props.navigation.history.push(
          `/CompanyProfileEdit/${responseJson.data[0].attributes.id}`
        );
      } else {
        this.props.navigation.history.push(
          `/startupDetails/${responseJson.data[0].attributes.id}`
        );
      }
    }
  }
  if(responseJson.error){
    toast.error(this.state.startUpPageText.pleaseCreateNewListing,
      {
        position : this.checkIfCondtionForToast( toast.POSITION.TOP_LEFT , toast.POSITION.TOP_RIGHT),
        style : {
          direction :  this.checkIfConditionForDirection()
        }});
    this.setState({ isListingModal: true });
  }
}
  

  getProfileDetails = async () => {
    const token = await getStorageData('token');
    const header = {
      // "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileInformation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getprofileDetailsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleApis = (responseAPIId: string, responseJson: any) => {
    if (this.getCompayOverViewId === responseAPIId) {
      this.companyOverView(responseJson);
    }
    if (this.getPrivateInformationId === responseAPIId) {
      this.getPrivateInformationFromApi(responseJson);
    }
    if (this.getProfileInformation === responseAPIId) {
      this.setState({ nameOftheUser: responseJson.data.attributes.first_name + " " + responseJson.data.attributes.last_name })
      this.setState({ prfileImagePath: responseJson.data.attributes.image?.url, profile_percent: responseJson.data.attributes.profile_percent });
    }
  }
  ClearAll = () => {
    this.onSummaryClear();
    this.onCompanyOverViewClear();
    this.onAqcuisitionDetailsClear();
    this.onFinancialClear();
    this.setState({ numberOfUsers: '' });
  }

  extractStartupSubCategories = () => {
    const startupCategory: any = this.state.categoriesList.find((category: any) => category.name === this.state.startUpPageText.startUpText);
    if (!startupCategory) {
      return [];
    }
    const subCategories = startupCategory.subCategories;
    
    if(!this.state.otherSubCatId) {
      subCategories.forEach((element: {name: string, id: string}) => {
        if(['Other', 'أخرى'].includes(element.name)) {
          this.setState({otherSubCatId: element.id});
        }
      });
  
    }
    
    return subCategories.map((sub: any) => ({
      value: sub.id.toString(),
      title: sub.name
    }));
  }

  handleSwitchChange = (checked: boolean) => {
    this.setState({ autoSignNDA: checked })
  }

getLang = async () => {
  let lang = await getStorageData('language')
  if (lang) {
    return this.setState({ language: lang })
  }
  this.setState({ language: 'en' })  
}

  // Customizable Area End
}
