Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getCatalogueAPIEndPoint = "bx_block_catalogue/listings/catalogues_listings";
exports.getCategoriesAPIEndPoint = "bx_block_categories/categories?sub_categories=true";

exports.reportApiEndPoint = "bx_block_catalogue/archieve_and_report";
exports.bookmarkListEndPoint = "bx_block_catalogue/catalogues_lists";
exports.bookmarkEndPoint = "bx_block_catalogue/create_bookmark";
exports.unBookmarkEndPoint = "bx_block_catalogue/update_bookmark_catalogue";
exports.startupMetricUpdateEndPoint = "bx_block_profile/start_ups/update_customer_metric";
exports.postCategoryEndPoint = "bx_block_categories/sub_categories/other_category";
exports.FinancialUpdateEndpoint = "bx_block_profile/start_ups/update_financial";
exports.companyOverviewEndpoint = "bx_block_profile/start_ups/update_company_profile";
exports.summaryOverViewEndpoint = "/bx_block_profile/start_ups/update_summarry";
exports.createListingEndpoint = "bx_block_catalogue/listings/create_listing"
exports.flagReportEndPoint = "bx_block_catalogue/reports";

exports.type = "Type";
exports.view = "View";
exports.allFilters = "All Filters";
exports.price = "Price";
exports.sortBy = "Sort By";
exports.reportSuccessTitle = "Success!";
exports.reportSuccessMsg = "Catalogue reported successfully.";
exports.copiedTxtMsg = "Link copied";
exports.copiedTxtMsgArabic = "تم نسخ الرابط";


exports.typeAr = "النوع";
exports.viewAr = "طرق العرض";
exports.allFiltersAr = "جميع الفلاتر";
exports.sortByAr ="الترتيب حسب";
exports.priceAr = "السعر";
exports.selectAllTxt = "اختر الكل";

exports.resions = [{
  icon: "ILD",
  title: "Inaccurate Listing Details"
},
{
  icon: "SOF",
  title: "Suspicious or Fraudulent"
},
{
  icon: "IOS",
  title: "Irrelevent or spam"
},
{
  icon: "report",
  title: "Inappropriate Content"
},
{
  icon: "DL",
  title: "Duplicate Listing"
},
{
  icon: "BOTOS",
  title: "Breach of Terms of Service"
}];

exports.reportSuccessMsg = "Catalogue reported successfully.";
exports.privateInformationupdateEndPoint = "bx_block_profile/start_ups/update_private_informations"
exports.getListApiEndPoint = "bx_block_catalogue/listings/user_list"
exports.acquisationUpdateEndPoint = "bx_block_profile/start_ups/update_acquisition_detail"
exports.companyOverViewEndPoint = "bx_block_profile/start_ups/get_company_overview"
exports.getPrivateInformation = "bx_block_profile/start_ups/get_private_information";

exports.clearTxt = "Clear";
exports.searchTxt = "Search";
exports.noRecFoundTxt = "No Records Found";
exports.oneListing = "1 listing"
exports.oneListingArabic = "قائمة واحدة"
exports.threeListing = "3 listings"
exports.threeListingArabic = "3 قوائم"
exports.askingPrice = "Asking Price";
exports.minimumTxt = "Minimum";
exports.maximumTxt = "Maximum"
exports.revenueMultiple = "Revenue Multiple";
exports.revenueGross = "LTM Gross Revenue";
exports.revenueGrossArabic = "إجمالي الإيرادات لآخر 12 شهر"
exports.grossProfit = "LTM Gross Profit"
exports.grossProfitArabic = "إجمالي الربح لآخر 12 شهر";
exports.locationTxt = "Location"

exports.privateInformationupdateEndPoint = "bx_block_profile/start_ups/update_private_informations"
exports.getListApiEndPoint = "bx_block_catalogue/listings/user_list"
exports.acquisationUpdateEndPoint = "bx_block_profile/start_ups/update_acquisition_detail"
exports.companyOverViewEndPoint = "bx_block_profile/start_ups/get_company_overview"
exports.getPrivateInformation = "bx_block_profile/start_ups/get_private_information"
exports.getprofileDetailsApiEndPoint = "account_block/accounts"
exports.businessTextArabic="الأعمال التجارية";

exports.businessText="Business";
exports.businessTextArabicNew = "عمل";
exports.businessTextArabic="الأعمال التجارية";
exports.startUpText="Start Up";
exports.startUpTextArabic = "شركة ناشئة";
exports.AnnualRevenueText =" Annual Recurring Revenue:";
exports.AnnualRevenueTextArabic = "الإيرادات السنوية:";
exports.annualProfitText="LTM Net Profit:";
exports.annualProfitTextArabic="الأرباح لآخر 12 شهر:";
exports.basedInText="Based in:";
exports.basedInTextArabic = "مقرها في:"
exports.numberOfEmployeesText="Number of employees:";
exports.numberOfEmployeesTextArabic=" عدد الموظفين:";
exports.foundedInText=" Founded in:";
exports.foundedInTextArabic=" تأسست في:";
exports.proposedEvalutionText="Proposed valuation:";
exports.proposedEvalutionTextArabic="التقييم المقترح:";
exports.originalListingText="Original listing in";
exports.bookmarkText="Bookmark";
exports.bookmarkTextArabic= "حفظ";
exports.archiveReportText="Archive / Report";
exports.shareListText="Share List";
exports.shareListTextArabic = "مشاركة القائمة"
exports.unBookMarkText="UnBookmark";
exports.archiveListingText="Are you sure you want to archive this listing?";
exports.archiveListingTextArabic= "هل أنت متأكد من إضافة القائمة للأرشيف؟";
exports.yesText="Yes";
exports.yesTextArabic = "نعم";
exports.noText="No";
exports.noTextArabic = "لا";
exports.ArchiveText="Archive";
exports.ArchiveTextArabic = "أرشفة";
exports.chooseThisWillText="Choosing this will";
exports.chooseThisWillTextArabic = "هذا الإختيار سوف ";
exports.hideTheListingText="hide the listing";
exports.hideTheListingTextArabic = "يخفي القائمة";
exports.reportText ="Report";
exports.reportTextArabic = "إبلاغ";
exports.flagTheListing = "archive the listing";
exports.flagTheListingArabic = "يضيف القائمة للأرشيف";
exports.flagBoxTitle = "Problem with this listing?";
exports.flagBoxTitleArabic = "هل هناك مشكلة في هذه القائمة؟";
exports.viaLinkText="Via link";
exports.TwitterText="Twitter";
exports.TwitterTextArabic = "تويتر";
exports.faceBookText="Facebook";
exports.faceBookTextArabic = "فيسبوك";
exports.maximumText="Maximum";
exports.MinimumText="Minimum";
exports.annualGrowthRateCatText="Annual Growth Rate";
exports.annualRecurringText="Annual Recurring Revenue";
exports.startupAgeText="Start up Age";
exports.startupAgeTextArabic = "عمر الشركة الناشئة";
exports.numberOfCustomersText="Number of Customers";
exports.instragramText="Instagram";
exports.instagramTextArabic = "انستغرام";
exports.whatsAppText="WhatsApp";
exports.whatsAppTextArabic = "واتساب";
exports.bookmarkModalTitle = "Add to list...";
exports.bookmarkModalTitleArabic = "أضف إلى قائمة...";
exports.addListTxt = "Create New List";
exports.addListTxtArabic = "إنشاء قائمة جديدة";
exports.shareBoxTitle = "Share this listing";
exports.shateBoxTitleArabic = "شارك هذه القائمة";
exports.flagBoxTitle = "Problem with this listing?";
exports.flagBoxDetails = "Encountering an issue with this listing? Report it to help us maintain quality, or archive it to keep your dashboard organized and easily restore it later if needed.";
exports.flagBoxDetailsArabic = "هل تواجه مشكلة في هذه القائمة؟ قم بالإبلاغ عنها لمساعدتنا في الحفاظ على الجودة، أو قم بأرشفتها للحفاظ على لوحة التحكم الخاصة بك واستعادتها بسهولة في وقت لاحق إذا لزم الأمر."
exports.shareListingDetails = "Know someone who might be interested in this business? Share the listing with your friends or colleagues and help them discover this great opportunity!";
exports.shareListingDetailsArabic = "هل تعرف شخصًا قد يكون مهتمًا بهذا العمل؟ شارك القائمة مع أصدقائك أو زملائك وساعدهم على اكتشاف هذه الفرصة الرائعة!";
exports.reportBlogTitle = "Why are you reporting this listing?";
exports.reportBlogTitleArabic = "لماذا تقوم بالإبلاغ عن هذه القائمة؟";
exports.nameOfTheStartupText = "Start Up";
exports.CompleteText="Complete"
exports.completeTextArabic = "كاملة";
exports.myListingText="My listing"
exports.myListingTextArabic = "قائمتي";
exports.completeYourProfileFullText=" Complete your profile to get verified and secure your spot on the marketplace"
exports.completeYourProfileFullTextArabic = `أكمل ملفك التعريفي ليتم التحقق من هويتك وتأمين مكانك في السوق`
exports.needHelpText="Need help with this?"
exports.needHelpTextArabic = "هل تحتاج إلى مساعدة في هذا؟";
exports.completeYourProfileText="Complete your profile"
exports.completeYourProfileTextArabic = "أكمل ملفك الشخصي";
exports.exploreListingText="Explore Listings"
exports.exploreListingTextArabic = "استكشف القوائم";
exports.PrivateInformationHeadingText="Private Information"
exports.privateInfoHeadingTextArabic = "معلومات خاصة";
exports.subHeadingText="Lorem Ipsum Dolor Set";
exports.viewFullProfileLink ="View full profile"
exports.startUpNameText="Company Name : "
exports.startUpNameTextArabic = "اسم الشركة : "
exports.startUpWebsiteText="Company Website :"
exports.startUpWebsiteTextArabic = "رابط موقع الشركة : "
exports.whoAreYourCompitatorsText="Who are your competitors ?:"
exports.whoAreYourCompitatorsTextArabic = `ما هي الشركات
المنافسة لك؟ `
exports.pAndLStatmentText="P & L Statement :"
exports.pAndLStatmentTextArabic = "بيان الأرباح والخسائر : ";
exports.uploadDocumentText="upload document.pdf";
exports.documentsAndFilesText="Documents & Files :";
exports.documentsAndFilesTextArabic = "المستندات والملفات : ";
exports.autoSignText="Auto Sign :";
exports.autoSignTextArabic = "توقيع تلقائي :";
exports.autoSignInLabelNda="Auto Sign NDA";
exports.autoSignInLabelNdaArabic = "توقيع اتفاقية عدم إفشاء تلقائي";
exports.autoSignAcceptText="Auto Accept Request";
exports.autoSignAcceptTextArabic = "طلب القبول التلقائي";
exports.dateFoundedText="Date Founded:";
exports.dateFoundedTextArabic = "تاريخ تأسيس الشركة";
exports.startUpTeamSizeText="Total Number of Employees:";
exports.startUpTeamSizeTextArabic = "إجـمالي عدد الموظفين";
exports.startUpTypeText="Business Type / Category:";
exports.startUpTypeTextArabic = "مجـال/صناعة الشركة";
exports.countryText="Country:";
exports.countryTextArabic = "البلد";
exports.BahrainText="Bahrain";
exports.EgyptText="Egypt";
exports.KuwaitText="Kuwait";
exports.OmanText="Oman";
exports.QatarText="Qatar";
exports.SaudiArabiaText="Saudi Arabia";
exports.uaeText="United Arab Emirates";
exports.CityText="City:";
exports.cityTextArabic = "المدينة : "
exports.headingText="Headline:";
exports.headingTextArabic = "العنوان الرئيسي : ";
exports.otherCatText="Other category name:";
exports.DescriptionText="Description:";
exports.descriptionTextArabic = "الوصف :";
exports. AskingPriceText=" Asking Price:";
exports.askingPriceArabic = "السعر المطلوب";
exports.AskingPriceReasonText="Rationale for the asking price:";
exports.askingPriceReasonTextArabic = "السبب للسعر المطلوب";
exports.thisFieldIsRequiredText="This Field is required";
exports.companyOverViewText="Company Overview";
exports.companyOverViewTextArabic = "نظرة عامة عن الشركة ";
exports.AcquisitionText="Aquisition Details";
exports.acquisitionTextArabic = "معلومات الاستحواذ ";
exports.summaryText="Summary";
exports.summaryTextArabic = "المعلومات العامة ";
exports.privateSubHeading = "This information will be shared with premium Nami buyers if a non-disclosure agreement is agreed between you and the buyer";
exports.privateSubHeadingArabic = "ستتم مشاركة هذه المعلومات مع مشتري نامي المتميزين إذا تم الاتفاق على اتفاقية عدم إفشاء بينك وبين المشتري";
exports.subheadingForAllText = "This information will be available to registered Nami Users"; 
exports.subheadingForAllTextArabic = "ستكون هذه المعلومات متاحة لمستخدمي نامي المسجلين";
exports.businessModelText="What is your business model and pricing ?:"
exports.businessModelTextArabic = "ما هو نموذج عمل/ نموذج التسعير لدى الشركة ؟";
exports.techStackText="What tech stack is this product or service build on ? : ";
exports.techStackTextArabic = "ما هو نظام التطوير المستخدم في تطوير منتجـكم؟ : ";
exports.listOfProductsText="List of products / servies:";
exports.listOfProductsTextArabic = `قم بتوضيح الخدمات والمنتجـات:`
exports.growthOppText="Growth Opportunity:";
exports.growthOppTextArabic = "فرص التوسع والنمو :"
exports.keyAssestsText=" Key Assests:";
exports.keyAssestsTextArabic = "أصول رئيسية ";

exports.reasonForSellingText="Reason for Selling :";
exports.reasonForSellingTextArabic = "سبب بيع الشركة :";
exports.finanacialSources = "Financing Sources:";
exports.finanacialSourcesArabic = "مصدر التمويل :";
exports.ltmGrassRevenueText="LTM Gross Revenue : ";
exports.ltmGrassRevenueTextArabic = "إجـمالي إيرادات آخر 12 شهر : ";
exports.ltmGrassToolTIpText="Enter the total amount of money your company has made from sales over the last twelve months before deducting any expenses";
exports.ltmGrossToolTipTextArabic = `أدخل المبلغ الإجمالي للأموال التي حققتها شركتك من المبيعات خلال الاثني عشر شهراً الماضية قبل خصم أي نفقات`;
exports.ltmNetProfitText="LTM Net Profit :";
exports.ltmNetProfitTextArabic = "صافي ربح آخر 12 شهر : ";
exports.ltmNetProfitToolTipText="Enter the amount of money that remains from your grass revenue after subtracting all the costs and expenses over the last twelve months";
exports.ltmNetProfitToolTipTextArabic = `أدخل المبلغ المالي المتبقي من إيراداتك من العشب بعد طرح جميع التكاليف والمصروفات على مدار الاثني عشر شهرًا الماضية`;
exports.annualRecurringRevenueText="Annual Recurring Revenue:";
exports.annualRecurringRevenueTextArabic = "الإيرادات السنوية المتكررة";
exports.annualRecurringToolTipText="Enter the amount of money that your company expects to regularly receive every year from things like subscriptions or ongoing services";
exports.annualRecurringToolTipTextArabic = "أدخل المبلغ المالي الذي تتوقع شركتك الحصول عليه بانتظام كل عام من أشياء مثل الاشتراكات أو الخدمات المستمرة";
exports.annualGrowthRateText="Annual Revenue Growth vs Last year :";
exports.annualGrowthRateTextArabic = `معدل نمو المبيعات السنوي
مقارنة بالسنة السابقة:`
exports.annualGrowthRateToolTipText="Enter the percentage that your company's revenue has increased compared to the previous ear. Do not include any negative numbers, even if your company didn't grow."
exports.annualGrowthRateToolTipTextArabic = "أدخل النسبة المئوية التي زادت بها إيرادات شركتك مقارنة بالأذن السابقة. لا تدرج أي أرقام سلبية، حتى لو لم تنمو شركتك.";
exports.lastMonthGrassRevenueText="Last Month Gross Revenue :";
exports.lastMonthGrossRevenueTextArabic = `اجمالي مبيعات آخر شهر: `
exports.lastMonthGrassRevenueToolTipText="Enter the total sales your company made in the most recent month without deducting any costs or expenses.";
exports.lastMonthGrossRevenueToolTipTextArabic = `أدخل إجمالي المبيعات التي حققتها شركتك في الشهر الأخير دون خصم أي تكاليف أو نفقات.`;
exports.lastMonthNetprofitText="Last Month Total cost :";
exports.lastMonthNetProfitTextArabic = "اجمالي التكاليف لآخر شهر"
exports.lastMonthNetprofitToolTipTextOne="Enter what's left"
exports.lastMonthNetProfitToolTipTextOneArabic = "أدخل ما تبقى"
exports.lastMonthNetprofitToolTipTextTow="from your last month's gross revenue";
exports.lastMonthNetProfitToolTipTextTwoArabic = "من إجمالي إيراداتك الشهر الماضي";
exports.lastMonthNetprofitToolTipTextThree="after all expenses have been paid.";
exports.lastMonthNetProfitToolTipTextThreeArabic = "بعد دفع جميع النفقات.";
exports.multipleTypeText="Multiple Type :";
exports.revenueToolTipHeadingTextOne="Revenue Multiple:";
exports.revenueToolTipContentTextone="3-10x revenue, high for tech startups due to growth potential.";
exports.arrMultipleTypeHeading="ARR Multiple:";
exports.arrMultipleContentText="3-8x annual recurring revenue, specific to subscription models.";
exports.multipleNumberText="Mutiple Number:";
exports.numberOfUsersText="Number of Users :";
exports.numberOfUsersTextArabic = "عدد المستخدمين :";
exports.saveText="Save";
exports.saveTextArabic = "حفظ"
exports.clearText="Clear";
exports.clearTextArabic = "حذف";
exports.addNewListingText="Add New Listing";
exports.addNewListingText_ar="إضافة قائمة جديدة";
exports.startUpText="Start Up";
exports.startUpText_ar="شركة ناشئة";
exports.smeText="SME";
exports.businessText="Business";
exports.businessText_ar="عمل";
exports.nextText="Next";
exports.nextText_ar="التالي";
exports.successText="Success!";
exports.successText_ar="نجاح!";
exports.listingSuccessFullyAddedText="Your listing has been successfully added!";
exports.listingSuccessFullyAddedText_ar="تمت إضافة الشركة بنجاح!"

exports.youCanAccessText="You can access it from the seller homepage";
exports.youCanAccessText_ar="يمكن الوصول للقائمة من الصفحة الرئيسية";

exports.uploadDocumentFileText="Upload document";
exports.titleText="Title :";
exports.addNewListingAfterText="Adding a new listing is activated after your current listing is 100% Complete";
exports.addNewListingAfterText_ar=" إضافة قائمة جديدة سيكون متاحا عند استكمال بيانات القائمة الحالية بنسبة 100%";
exports.addingNewListingCompleteText="Adding a new listing is activated after your current listing is 100% Complete";
exports.otherText="Other";
exports.otherTextArabic = "أخرى";
exports.customerAndVerdorTextArabic = "مقياس العملاء و البائعين";
exports.financesHeadingArabic = "المعلومات المالية ";
exports.businessContent = "An established entity that offers products or services i.e. manufacturing, trading, brokerage, services etc. (e.g., A family-owned fashion retail brand)";
exports.businessContent_ar ="كيان مؤسس يقدم منتجات أو خدمات مثل التصنيع والتجارة والوساطة والخدمات وما إلى ذلك (على سبيل المثال، علامة تجارية لبيع الأزياء بالتجزئة مملوكة لعائلة)"
exports.startUpContent ="Tech-oriented businesses with innovative ideas aiming for rapid growth by addressing a unique market need (e.g., An online food delivery platform)";
exports.startUpContent_ar ="الشركات الموجهة نحو التكنولوجيا والتي تتمتع بأفكار مبتكرة تهدف إلى النمو السريع من خلال تلبية احتياجات السوق الفريدة (على سبيل المثال، منصة توصيل الطعام عبر الإنترنت)"
exports.financialSources = [
  { title: "Angel Investers", value: "angel_investor" },
  { title: "Incubator / Accelerators", value: "incubators_accelerators" },
  { title: "VC Backend", value: "vc_backed" },
  { title: "Bootstrap/Self-Funded", value: "bootstrap_self_funded" },
  { title: "Corporate Funded", value: "corporate_funded" },
]

exports.financialSourcesArabic = [
  { title: "مستثمر مالئكي ", value: "angel_investor" },
  { title: "مسرعات/ حاضنات أعمال ", value: "incubators_accelerators" },
  { title: "استثمار جـريء ", value: "vc_backed" },
  { title: "استثمار شخصي ", value: "bootstrap_self_funded" },
  { title: "تمويل شركات ", value: "corporate_funded" },
  { title: "أخرى", value: "other" }
]
exports.arabicCountriesData = [{icon: "https://flagcdn.com/sa.svg",country:"المملكة العربية السعودية",cities:["الرياض","جدة","مكة المكرمة","المدينة المنورة","الدمام","الخبر","الظهران","الجبيل","الطائف","بريدة",]},{icon:"https://flagcdn.com/om.svg" ,country:"عُمان",cities:["مسقط","السيب","السويق","عبري","صحم","بركاء","الرستاق","صلالة","بوشر","صحار",]},{icon:"https://flagcdn.com/eg.svg" ,country:"مصر",cities:["القاهرة","الإسكندرية","الجيزة","شبرا الخيمة","بورسعيد","السويس","الأقصر","أسيوط","الإسماعيلية","طنطا",]},{icon: "https://flagcdn.com/qa.svg",country:"قطر",cities:["الدوحة","الوكرة","مسيعيد","مدينة الشمال","أم صلال علي","أم صلال محمد","الريان","الخور",]},{icon: "https://flagcdn.com/ae.svg",country:"الإمارات العربية المتحدة",cities:["دبي","أبو ظبي","الشارقة","العين","عجمان","الفجيرة","أم القيوين","رأس الخيمة",]},{icon: "https://flagcdn.com/bh.svg",country:"البحرين",cities:["المنامة","الرفاع","المحرق","مدينة حمد","عالي","مدينة عيسى","سترة","البديع",]},{icon: "https://flagcdn.com/kw.svg",country:"الكويت",cities:["مدينة الكويت","حولي","السالمية","الفروانية","الفنطاس","الجهراء","صباح السالم","الأحمدي","مبارك الكبير",]}];


exports.keyAssets = [
  { title: "Codebase and IP", value: "codebase_and_ip" },
  { title: "Social Media Accounts", value: "social_media_accounts" },
  { title: "Customers", value: "customers" },
  { title: "Website", value: "website" },
  { title: "Marketing Materials", value: "marketing_materials" },
  { title: "Mobile Application", value: "mobile_application" },
  { title: "Brand", value: "brand" },
  { title: "Domain", value: "domain" },
];

exports.keyAssetsArabic = [
  { title: "قاعدة برمجـية ", value: "codebase_and_ip" },
  { title: "منصات تواصل اجـتماعي", value: "social_media_accounts" },
  { title: "قاعدة عمالء ", value: "customers" },
  { title: "موقع الكتروني ", value: "website" },
  { title: "مواد تسويقية ", value: "marketing_materials" },
  { title: "تطبيقات الكترونية", value: "mobile_application" },
  { title: "اسم تجـاري ", value: "brand" },
  { title: "اسم الدومين ", value: "domain" },
];

exports.growthOpportunitiesArabic = [
  { title: "تحسين معدل التحويل", value: "improve_conversion_rates" },
  { title: "تكثيف المحتوى التسويقي", value: "increase_content_marketing" },
  { title: "التوسع لاستهداف أسواق جـديدة ", value: "expand_to_new_markets" },
  { title: "رفع الأسعار", value: "increase_pricing" },
  { title: "توظيف فريق مبيعات محترف ", value: "hire_b2b_sales_team" },
  { title: "تحسين محركات البحث", value: "focus_on_seo" },
  { title: "تكثيف التسويق الإلكتروني ", value: "increase_digital_marketing" },
  { title: "إضافة مزايا جـديدة على المنتج ", value: "add_new_product_features" },
  { title: `التسويق على منصات التواصل
الاجـتماعي`, value: "social_media_marketing" }
];

exports.growthOpportunities = [
  { title: "Improve Conversion Rates", value: "improve_conversion_rates" },
  { title: "Increase Content Marketing", value: "increase_content_marketing" },
  { title: "Expand to New Markets", value: "expand_to_new_markets" },
  { title: "Increase Pricing", value: "increase_pricing" },
  { title: "Hire a B2B Sales Team", value: "hire_b2b_sales_team" },
  { title: "Focus on SEO", value: "focus_on_seo" },
  { title: "Increase Digital Marketing", value: "increase_digital_marketing" },
  { title: "Add New Product Features", value: "add_new_product_features" },
  { title: "Social Media Marketing", value: "social_media_marketing" }
];
exports.arrStringValue = "arr_multiple";
exports.successMessageToastText="Details updated successfully";

exports.pleaseCreateNewListing = "Listing is unavailable please create new list";

exports.successConfirmTextLine1="Your application has been successfully submitted.";
exports.successConfirmTextLine1_ar="تم تقديم طلبك بنجاح.";

exports.successConfirmTextLine2 = "We will review your application and get back to you shortly."
exports.successConfirmTextLine2_ar = "سوف نقوم بمراجعة طلبك و العودة إليك قريبا";

exports.successConfirmTextLine4 = "Your Listing ID is : "
exports.successConfirmTextLine4_ar = "الرمز الخاص بقائمتك: "


exports.successConfirmTextLine3 = "Your application ID is: "
exports.under_review = "#8C8C8C"
exports.accepted = "#10BFB8"
exports.rejected = "#FF7575"
exports.selectedMenuList = [
  { icon: "https://flagcdn.com/sa.svg", value: "Saudi Arabia", title: "Saudi Arabia" },
  { icon: "https://flagcdn.com/bh.svg", value: "Bahrain", title: "Bahrain" },
  { icon: "https://flagcdn.com/eg.svg", value: "Egypt", title: "Egypt" },
  { icon: "https://flagcdn.com/kw.svg", value: "Kuwait", title: "Kuwait" },
  { icon: "https://flagcdn.com/om.svg", value: "Oman", title: "Oman" },
  { icon: "https://flagcdn.com/qa.svg", value: "Qatar", title: "Qatar" },
  {
    icon: "https://flagcdn.com/ae.svg", value: "United Arab Emirates",
    title: "United Arab Emirates",
  },
];
exports.selectedMenuListAr =  [
  {icon: "https://flagcdn.com/sa.svg",value:"المملكة العربية السعودية",title: "المملكة العربية السعودية"},
  {icon:"https://flagcdn.com/om.svg" ,value:"عُمان",title:"عُمان"},
  {icon:"https://flagcdn.com/eg.svg" ,value:"مصر",title:"مصر"},
  {icon: "https://flagcdn.com/qa.svg",value:"قطر",title:"قطر"},
  {icon: "https://flagcdn.com/ae.svg",value:"الإمارات العربية المتحدة",title:"الإمارات العربية المتحدة"},
  {icon: "https://flagcdn.com/bh.svg",value:"البحرين",title:"البحرين"},
  {icon: "https://flagcdn.com/kw.svg",value:"الكويت",title:"الكويت"}]
exports.selectedcityList = [
  {country: "Saudi Arabia",cities: [ "Jubail", "Taif", "Mecca", "Medina", "Riyadh", "Jeddah", "Khobar", "Dhahran", "Dammam","Buraydah",],},
  {country: "Oman",cities: ["Sohar","As Suwayq","Ibri","Saham","Barka","Rustaq","Muscat","Seeb","Salalah","Bawshar"],},
  {country: "United Arab Emirates",cities: ["Dubai","Abu Dhabi","Umm Al-Quwain","Ras Al Khaimah","Sharjah","Al Ain","Ajman","Fujairah",],},
  {country: "Qatar",cities: ["Doha","Al Wakrah","Umm Salal Ali","Al Rayyan","Al Khor","Mesaieed","Madinat ash Shamal","Umm Salal Muhammad",],    },   
  {country: "Kuwait",cities: ["Kuwait City","Hawalli","Salmiya","Fintas","Jahra","Sabah Al Salem","Al Ahmadi","Mubarak Al-Kabeer","Farwaniya",]},   
  {country: "Bahrain",cities:  [ "Riffa", "Muharraq", "Hamad Town", "Manama", "A'ali", "Isa Town", "Sitra", "Budaiya",]},
  {country: "Egypt",cities: ["Cairo",   "Alexandria","Suez","Luxor","Asyut","Ismailia","Tanta","Giza","Shubra El Kheima","Port Said",]},   
];
exports.listingStatusEn = {
  "accepted": "Accepted",
  "rejected": "Rejected",
  "under_review": "Under Review"
}

exports.listingStatusAr = {
  "قبلت": "#10BFB8",
  "مرفوض": "#FF7575",
  "قيد المراجعة": "#8C8C8C"
}

exports.dealClosedText = "Deal Closed";
exports.dealClosedTextArabic = "تم إغلاق الصفقة";

exports.peText = "(6.0x P/E)";
exports.unbookmark = "Unbookmark";
exports.arabicUnbookmark = "إلغاء الحفظ";
exports.startText = "*"
// Customizable Area End